// Docket Alarm "common" javascript library. Code that is generally used across all page.
// TODO: Split libraries up into smaller pieces, and export individually.
import * as dockets from "../site_media/dockets.js";
window.dockets_js = dockets;

import * as dockets_track from "./dockets_track.js";
window.dockets_track = dockets_track;

// Matter input widget, auto-loads and attaches
import * as matter_widget from "./matter_widget.js";
// Also has one export.
window.matter_widget = matter_widget;


// import * as pleadingtags from "../site_media/pleading_tags.js";
