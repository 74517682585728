window.TomSelect = require('../src_libs/tom-select.complete');
import { get_all_matter_nos } from './dockets_track.js';
// Functions loaded from common.js at runtime:
// import {sessionSet, sessionGet, show_usermsg, sessionClear, show_confirm_usermsg} from "../site_media/common.js";

/**
 *
 * @param $obj
 * @param matter_obj
 * @returns {{text: *, value: (string|*)}|null}
 * @private
 */
function _matter_obj_to_item($obj, matter_obj) {
	if(!matter_obj) {
		// Pull the value from the input if it exists.
		matter_obj = $obj.val();
		if(!matter_obj) {
			return null;
		}
	}
	if(typeof matter_obj == "string") {
		try {
			var matter_parsed = JSON.parse(matter_obj);
			// Parsed it, make sure it's an object, not an int/float/string.
			matter_obj = typeof matter_parsed == "object" ? matter_parsed: {id: matter_obj};
		} catch (e) {
			// Create a matter object from the string directly.
			matter_obj = {id: matter_obj};
		}
	}

	// Calculate the displayed value.
	var matter_option = matter_obj.description || matter_obj.id;

	// Calculate the underling option.
	var hashed_obj = {};
	for(var k in (matter_obj || {})) {
		// Only include these keys in the valid options.
		if(["description", "id", "type"].indexOf(k) != -1) {
			hashed_obj[k] = matter_obj[k];
		}
	}
	return {value: JSON.stringify(hashed_obj), text: matter_option};
}
function _matter_item_to_display_html(item, escape) {
	var item_html = escape(item.text);
	try {
		var obj = JSON.parse(item.value);
		if (obj.type == "clio") {
			item_html += ' <span class="matter_option_connected">Clio</span>';
		}
	} catch (e) {

	}
	return item_html;
}

/**
 * Converts a matter value to the input value that can be user-edited.
 * Serves as a hash of sorts.
 * @param value
 * @returns {*|string}
 * @private
 */
function _matter_value_to_input(value) {
	try {
		var obj = JSON.parse(value);
		// Return the human readable description to edit.
		return obj.description || obj.id || "";
	} catch (e) {
		return value;
	}
}

/**
 * Deduplicate the options list based on our the
 * _matter_value_to_input as a hash key. This deduplicates the visually similar matters.
 * @param options
 * @param selected_item
 * @returns {*[]}
 * @private
 */
function _dedup_options(options, selected_item) {
	// When we dedup, we need to remove some. We want to keep the "longest"
	options.sort(function (a, b) {
		// Sort by the longest value, generally the longer the
		// matter number value, the more details in the object (e.g., clio).
		return b.value.length - a.value.length;
	});
	// Add the selected item, but only if it's not already in the list.
	var input_vals = {};
	options = options.filter(function (it, idx) {
		var key = _matter_value_to_input(it.value);
		if(input_vals[key]) {
			console.log("Dedup matter: " + key + " idx: " + idx + " --> " + (input_vals[key]-1));
			return false;
		}
		input_vals[key] = idx + 1;
		return true;
	});
	if(selected_item && !input_vals[_matter_value_to_input(selected_item.value)]) {
		options = [selected_item].concat(options);
	}
	// Now sort back to standard alphabetical order based on the text field.
	options.sort(function (a, b) {
		return a.text.localeCompare(b.text);
	});
	return options;
}

/**
 * Creates a matter input widget.
 * @param $location The jquery object to attach the widget to.
 * @param opts An object which allows additional options. Currently only for disabling plugins.
 * @param matter_obj Optional matter object to pre-populated the selected value.
 * @returns A TomSelect object, see their API: https://tom-select.js.org/docs/
 */
function create_matter_input_widget($location, opts, matter_obj) {

	$location.wrap('<div class="matter_container"></div>');

	// Figure out the selected matter number.
	var selected_item = _matter_obj_to_item($location, matter_obj);

	var base_matter_placeholder = $location.attr('placeholder');
	var matter_wildcard_placeholder = ''; // TODO: fetch the real wildcard placeholder
	// If we've loaded the remote matter numbers.
	var loaded_options = false;

	var plugins = {
		// adds a header to the dropdown
		dropdown_header: { title: 'Matters ...' },
		// adds a clear button
		clear_button: {'html':
			function(data){
				return '<div class="clear-button" title="Clear All"><p class="button-x">⨯</p></div>';
			}
		},
		// enables input auto grow
		input_autogrow: {}
	};
	// Disable a plugin if an option is passed in set to false.
	for(var k in plugins) {
		if(opts && opts[k] === false) {
			delete plugins[k];
		}
	}

	if($location.length && $location[0].tomselect) {
		// If the widget already exists, update it.
		$location[0].tomselect.destroy();
	}

	// initialize the selector
	var control = new TomSelect($location, {
		create: true, // user can create
		createOnBlur: true, // create item on blur
		// Don't store user entered values, creates duplicates and confusion
		persist: false,
		// Generally don't want repeats.
		duplicates: false,
		maxItems: 1,
		// Options that the user can choose from, will auto-load more below.
		options: selected_item ? [selected_item] : [],
		// The selected item.
		items: selected_item ? [selected_item.value] : [],
		placeholder: base_matter_placeholder, // input placeholder
		load: function(query, callback) {
			if(loaded_options){
				callback(loaded_options);
				return;
			}
			// Get all matter numbers from my case alerts
			get_all_matter_nos(function (all_matters) {
				// Format them how tomselect expects
				var options = all_matters.map(function (it) {
					return _matter_obj_to_item($location, it);
				});
				// Deduplicate the options list, along with the selected item.
				options = _dedup_options(options, selected_item);
				callback(options);
				loaded_options = options;
			});
		},
		plugins: plugins,
		onType: function() {
			// If the user is typing, clear out any matter error.
			$location.parents(".matter_error").removeClass("matter_error");
			$.powerTip.hide(null, true);
		},
		onFocus: function() {
			// if there is a selected item, set the input to that value
			if (this.items) {
				// restore the value if there is a selected value
				this.setTextboxValue(_matter_value_to_input(this.items[0]));
				// Clear any existing tooltips when we hover to make room for the matter dropdown.
				$location.parents(".matter_error");
				$.powerTip.hide(null, true);
			}
			// when the focus is on the input, if there is a placeholder wildcard, show it
			this.settings.placeholder = matter_wildcard_placeholder;
		},
		onBlur: function() {
			// when the input loses focus, show the base placeholder
			this.settings.placeholder = base_matter_placeholder;
			if(this.items) {
				this.setTextboxValue(this.items[0]);
			}
			this.inputState();
		},
		onItemAdd: function(value, item) {
			if (!value) // if there is no value we don't want to save it
				this.items = [];
			this.blur(); // blur the input to confirm selection
			$location.parents(".matter_error").removeClass("matter_error");
		},
		onClear: function() {
			if ($(this.wrapper).hasClass('focus')) {
				// if the input is in focus, we want to clear it as well
				// input is in focus when the wrapper has the class 'focus'
				$(this.control_input).val('');
			}
			// Clear the error state as well.
			$location.parents(".matter_error").removeClass("matter_error");
			this.refreshOptions(false);
		},
		onOptionAdd: function(key, data) {
			// Avoid duplicate options due to non-visible difference in matter objects.
			var existing = {};
			// Build a list of the existing options, hashed into display values.
			for(var k in this.options) {
				var input_k = _matter_value_to_input(this.options[k].value);
				existing[input_k] = true;
			}
			if(_matter_value_to_input(existing[data.value])) {
				// If the option already exists, remove it.
				this.removeOption(data.value);
			}
		},
		render : {
			option: function(item, escape) {
				return '<div class="matter_option">' + _matter_item_to_display_html(item, escape) + '</div>';
			},
			item: function(item, escape) {
				return '<div class="matter_item">' + escape(item.text) + '</div>';
			},

		}
	});
	// Pull the matter number from the form and set it as the selected value
	control.load();

	var add_validator_button = opts.add_validator_button || false;
	// if the matter validator url is set, create the button for 3rd party validator
	if (add_validator_button && matter_validation_enabled)
		create_matter_validator_button($location);

	return control;
}

function create_matter_validator_button($location, wrapper_type) {
	if (!wrapper_type) {
		wrapper_type = 'div';
	}
	var href = '/set_matter_redirect.ajax?da_origin_url=' + encodeURIComponent(window.location.href);
	var button_html = "<" + wrapper_type + " class='matter_validation_button_container'>" +
		"<a class='matter_validation_button' href='" + href + "'>" +
	"<i class='far fa-chevrons-right'></i></a>" + "</" + wrapper_type +">";
	$location.parent().addClass('has_validator_button').append(button_html);// append to the parent
	$location.parent().find('.matter_validation_button').click(function _click_validator() {
		// Save a selector to the button that selected the validator, so we can refer to later.
		var selector = $location.attr('id') ? "#" + $location.attr('id') : "";
		// Set it as a global cache:
		sessionSet('matter_validator_selector', selector);
	})
	.data("powertip", "Select a validated matter").powerTip({
            popupClass : 'small_powertip',
            placement: 's',
        });
}

/**
 * When 3rd party validator returns to their previously visited page, this
 * function handles showing status and error message, if there is one.
 */
function handle_3rd_party_validator_matter_return_status() {
	// Extract and parse the URL hash value.
	var searchParams = new URLSearchParams(window.location.hash.substring(1));
	// set_matter_success is the true/false for matter validation success.
	var set_matter_success = ((searchParams && searchParams.get('set_matter_success')) || '').toLowerCase();

	// Pull the ID to find the correct element
	var selector = sessionGet('matter_validator_selector');
	if(selector == null) {
		// No selector was found. Don't show a message.
		console.warn('No selector found for matter validator.');
		return;
	}
	// Clear the session data, so it's only shown once.
	sessionClear('matter_validator_selector');
	var $matter_field = selector && $(selector);
	// If set, the user gets a confirmation otherwise, they get an error popup
	// with the message explaining what went wrong.
	if (set_matter_success === 'true') {
		// Successfully set. Show a message later, so not immediately on page load.
		setTimeout(function() {
			show_usermsg('Matter updated.', $matter_field, 'info');
		}, 1000);
	} else if (set_matter_success === 'false') {
		// unsuccessfully set, show error
		var msg = searchParams.get('error_msg') || 'Could not set matter';
		// Convert from HTML to text to sanitize.
		const sanitized = $("<p>").text(msg).text()
		show_confirm_usermsg({
			title : 'Error',
			subtitle : sanitized,
			hide_cancel : true,
			dialog_class : 'matter_error'
		});
	}
}

$(document).ready(function() {
	// Create the input widgets.
	$(".actions .userinfoform input[name=pacerdefaultmatter]").each(function() {
		create_matter_input_widget($(this), {add_validator_button: true}, null);
	});
	handle_3rd_party_validator_matter_return_status();
});

export {
	create_matter_input_widget
};