// dockets.js - Javascript for the docket tracking.
// In here:
// 		- Group Billing Options
// 		- Analytics Workbench and docket shared functionaltiy.
//		- Bulk Updating Dockets
//		- Low-level highlighting code
/*
Don't import, but rely on the following being globally defined from common.js.
import {
	plural_n, capitalize, join_jquery,

	onEvent,

	analytics_track, show_paywall_if_necessary,
	wrap_html, update_billing_info, borderless_dialog,
	show_usermsg, show_error_usermsg,

	add_global_dropdown,

	export_data_to_excel,

	modal_progress_start, modal_progress_set, modal_progress_close
} from "../site_media/common.js";
*/

/**
 * Granted and denied keys used for coloring. They can be edited here.
 **/
var granted_keys = [
	"granted",
	"affirmed",
	"sustained"
];
var denied_keys = [
	"denied",
	"reversed",
	"dismissed"
];
// An outcome can potentially meet both granted and denied and be mixed.
var _granted_re = RegExp(granted_keys.join("|") +
	// Also include negatives.
	"|(not (" + denied_keys.join("|") + "))");
var _denied_re = RegExp(denied_keys.join("|") +
	// Include oduble negatives.
	"|(not (" + granted_keys.join("|") + "))");
function granted_test(outcome) {
	var m = _granted_re.exec(outcome);
	return m && !outcome.substring(0, m.index).endsWith('not ');
}
function denied_test(outcome) {
	var m = _denied_re.exec(outcome);
	return m && !outcome.substring(0, m.index).endsWith('not ');
}
// Setup the color categories, positive are green, negative red, mixed yellow.
var outcome_color_key = {};
granted_keys.forEach(function (gk) {
	outcome_color_key[gk] = "Greens";
	denied_keys.forEach(function (dk) {
		outcome_color_key[gk + " and " + dk] = "Yellows";
	});
});
denied_keys.forEach(function (dk) {
	outcome_color_key[dk] = "Reds";
});

///////////////
function set_object_to_docket_info($obj, track) {
	$obj
		.find("a.view").attr('href',track.url).end()
		.find('.title').text(track.title).end()
		.find('.court').text(track.court).end()
		.find('.court_id').text(track.court_id).end()
		.find('.docket').text(track.docket).end()
		.find('.link').text(track.link).end()
		.find('.sig, .signature').text(track.signature).end();
	return $obj;
}
function get_docket_info_from_object(row) {
	if(row.find(".json")) {
		// Allow the data to be encoded within a json string.
		var json = $.parseJSON( row.find(".json").text() );
		if(json && json.signature && !json.sig) {
			json.sig = json.signature;
		}
		if(json && json.title && json.docket != null && json.court) {
			return json;
		}
	}
	var court = row.find(".court").text() || "";
	return {
		title : row.find(".title a").text() || row.find("div.title").text() ||
					row.find(".title").text() || "",
		docket : row.find(".docket").text() || "",
		court : court,
		court_cite : row.find(".court_cite").text() || court,
		court_id : row.find(".court_id").text() || "",
		link : row.find(".link").text() || "",
		sig : row.find(".sig").text() || ""
	}
}

// Handle the search progress bar
var progress_bar_progeress = {};

/**
 * Start an automatic progress bar. You do not have to provide updates to
 * this status bar, it just increases getting closer to 100% but never
 * reaching it.
 * @param progress_bar 		The DOM object that you'd like to be a progress bar.
 * @param expected_time		Time in secs you expect the progress bar to run.
 * @param key				(Optional) Unique ID specific to this progress bar.
 */
function start_progress_bar(progress_bar, expected_time, key) {
	if (!progress_bar)
		progress_bar = ".progress_bar";
	if (!expected_time)
		expected_time = 30;
	if(!key) key = "all";
	// console.log("start " + key);
	progress_bar_progeress[key] = 5;
	$(progress_bar).progressbar({ value: 0 }).show()
		.find(".text").text("");
	// Set a short interval step so it's smooth.
	var interval_ms = 75;
	// Number of intervals is total time divided by interval time.
	var number_of_intervals = expected_time * 1000 / interval_ms;
	// Now figure out the steps from 0 to 100.
	var interval_step = 100 / number_of_intervals;
	add_to_progress(progress_bar, interval_ms, key, interval_step);
}
function stop_progress_bar(key) {
	if(!key) key = "all";
	// console.log("stop " + key)
	progress_bar_progeress[key] = -1;
}
function add_to_progress(progress_bar, interval_ms, key, amount) {
	// console.log("add " + key)
	if(progress_bar_progeress[key] < 0) {
		// Stop the search bar
		$( progress_bar ).hide();
		return;
	}
	progress_bar_progeress[key] += amount;
	// Simulate a slowly approaching progress bar.
	var perc = 100-100*Math.pow(Math.E, -progress_bar_progeress[key]/100*2);
	// The exponentially increasing search bar... so effective, so evil.
	$( progress_bar ).progressbar({ value: perc });
	setTimeout(function() {
		add_to_progress(progress_bar, interval_ms, key, amount);
	}, interval_ms);
}
function set_progress_bar_text(text) {
	$(".progressbar").each(function () {
		var $this = $(this);
		var $text = $this.find(".text");
		if(text && !$text.length) {
			$text = $this.append("<div class='text'></div>").find(".text");
		}
		$text.text(text || "");
	});
}

$(document).ready(function(){
	$("input[type='submit']").button();
	
	function panel_show(panel) {
		panel.show();
		panel.render(document.body);
		panel.center();
	}
});

/*********************************************************************
 *
 *  Utilities for Docket Tracking Tool
 *
 *********************************************************************/

/**
 * Highlight some html with an expression
 * be sure to escape the regex with RegExp.escape
 */
function highlight(html, expr) {
	$(html).contents().each(function() { 
		highlight_inner(this, expr);
	});
	return html;
}
function highlight_inner(el, expr) {
	var created = 0;
	if(el.nodeType == 3 /*Node.TEXT_NODE, hardcode for IE*/) {
		var parentClassList = getClassList(el.parentNode);
		if(parentClassList && parentClassList[0] == "highlighted") {
			// We already highlighted this parent node
			return 0;
		}
		var found, pos, text, spannode, middle, end, middleclone, elmiddle, elend;
		// console.log(el.data)
		while (found = expr.exec(el.data)) {
			pos 	= found.index;
			text 	= found[0];
			
			// Create the highlighted span node
			spannode = document.createElement("span");
			spannode.className = "highlighted";
			
			// Split the old node into three (el, middle, end)
			elmiddle = el.splitText(pos);
			elend = elmiddle.splitText(text.length);
			middleclone = elmiddle.cloneNode(true);
			
			spannode.appendChild(middleclone);
			elmiddle.parentNode.replaceChild(spannode, elmiddle);
			created++;
			el = elend; // Set el to the end so we can continue looking
		}
		return created;
	} else {
		// console.log("Processing " + el.tag + ", " + el.childNodes.length + "children.")
		for (var child = 0; child < el.childNodes.length; child++) {
			child += highlight_inner(el.childNodes[child], expr);
		}
		return 0;
	}
}

/**
 * Inner function, made global for performance.
 * @private
 */
function _remove_each_highlight(node) {
	var parent = node.parentNode;
	if(!parent) {
		// Already removed from DOM.
		return;
	}
	var prev = node.previousSibling;
	prev.appendData(node.innerHTML);
	parent.removeChild(node);
	if(prev.nextSibling.nodeType == 3 /*Node.TEXT_NODE, for IE*/) {
		prev.appendData(prev.nextSibling.data);
		parent.removeChild(prev.nextSibling);
	}
}
var remov_run = {};

/**
 * Remove all the highlights in the selector.
 * @param sel
 * @param _progress If set, will run asynchronously, and periodically
 * 					send progress updates.
 * @param _done Fucntion that accepts true/false if we broke early.
 * @returns {number} Number of items that will be/were removed.
 */
function remove_highlights(sel, except_data_i, _progress, _done) {
	if(except_data_i && typeof except_data_i != "object") {
		console.warn("Expecting an object.");
		except_data_i = null;
	}
	if(!sel) sel = "*";
	var highlights = $(sel).find("span.highlighted");
	var num_remove = highlights.length;
	var cur_remove_run;
	if(remov_run[sel]) {
		remov_run[sel]++;
	} else {
		remov_run[sel] = 1;
	}
	cur_remove_run = remov_run[sel];
    function do_highlights(start) {
		if(remov_run[sel] != cur_remove_run) {
			console.log("Remove highlights skipped - " + sel + ": " +
				remov_run[sel] + " != " + cur_remove_run);
			if(_done) {
				_done(true);
			}
			return;
		}
		if(start >= highlights.length) {
			if(_done) {
				_done();
			}
			return;
		}
		highlights.slice(start).each(function (index, value) {
			var do_remove = true;
			if(except_data_i){
				var parent = $(this).parents(sel);
				var data_i = parent.data("i");
				if(data_i) {
					do_remove = !except_data_i[parseInt(data_i)] &&
						!except_data_i[data_i];

				}
			}
			if(do_remove) {
				_remove_each_highlight(value);
			}
			index += start;
			if(_done && index >= num_remove - 1) {
				_done();
			} else if(_progress && index % 200 == 199) {
				_progress(index, num_remove, function () {
					setTimeout(function () {
                        do_highlights(index + 1);
                    }, 1);
				});
				return false;
			}
		});
	}
	do_highlights(0);
	return num_remove;
}

//////////////////////////////////
// Show the paywall if necessary.

$(document).ready(function(){	
	// Check billing information on every page load
	if(user_logged_in) {
		var do_force = typeof force_update_billing_info_on_load != "undefined";
		if(!do_force) {
			// set_matter_success conveys successful matter validation. If set,
			// billing info should be reloaded since it contains the matter.
			var hash = new URLSearchParams(window.location.hash.substring(1));
			do_force = hash && (hash.get('set_matter_success') || '').toLowerCase() === 'true';
		}
		update_billing_info(do_force);
	}
	show_paywall_if_necessary();
});


//////////////////////////////////
// Group Billing

function get_group_billing_info(done, iter) {
	var group_billing = sessionGet("groupbilling_info");
	if(group_billing && group_billing.group) {
		done(group_billing);
		return;
    }
	// Group billing information is not yet ready.
	console.warn("Waiting for Group Billing Info.");
	var max_time_msec = 20000; // Users with large groups can take a while.
	var wait_time_msec = 250;
	var max_iters = max_time_msec / wait_time_msec;
	if(!iter || iter < max_iters) {
		if(iter == 10) {
			console.warn("Requesting New Group Billing Info.");
			update_groupbilling_info();
		}
		// Run it again in a moment.
		setTimeout(function () {
			get_group_billing_info(done, (iter || 0) + 1);
		}, wait_time_msec);
		return;
	}
	console.warn("Waited too long, loading anyway.");
	return null;
}

/**
 * Generate an excel with information about all users.
 * @param done	A function that will be called when complete.
 * @param export_user_list	Export the list of users (default: true).
 * @param export_ecf_list Export the list of ECF integrations (default: true).
 */
function export_group_billing(done, export_user_list, export_ecf_list) {
	if(!done) {
		done = function () { };
	}
	export_user_list = export_user_list !== false;
	export_ecf_list = export_ecf_list !== false;
	if(!export_user_list && !export_ecf_list) {
		return
	}

	var is_canceled = false;
	// Start a progress bar.
	modal_progress_start("export_users", "Export User Information", function () {
		is_canceled = true;
	});
	modal_progress_set("export_users", 25, "Pulling user data.");
	// Get detailed information.
	$.getJSON("/get_groupbilling_info.ajax?add_user_info=true", {}, function(resp) {
		if(is_canceled) {
			done();
			return;
		}
		if(!resp.success) {
			modal_progress_error("export_users", resp.error);
			done();
			return;
		}
		modal_progress_set("export_users", 75, "Generating Excel.");

		// Generate the excel data rows.
		var user_rows = [], ecf_rows = [];
		var user_count = 0, user_accepted_count = 0;
		var ecf_user_count = 0, ecf_count = 0;
		resp.offered_to_others.forEach(function (u) {
			user_count += 1;
			user_accepted_count += u.accepted ? 1 : 0;
			var status = u.accepted ? "Accepted" : u.rejected ? "Rejected Invite" : "Invited";
			var tags = u.settings && u.settings.tags ? u.settings.tags.join("; ") : "";
			// Create a row for the users.
			user_rows.push([u.name, u.email, tags, status, u.user_info ? u.user_info.joined_on : ""]);

			var ecf_users = (u.user_info && u.user_info.integration && u.user_info.integration.ecf) || [];
			var found_ecf = false;
			for(var e_i=0; e_i < ecf_users.length; e_i++) {
				var ecf = ecf_users[e_i];
				if(ecf.enabled) {
					ecf_count += 1;
					found_ecf = true;
					ecf_rows.push([u.name, u.email, ecf.court, ecf.username, ecf.date])
				}
			}
			if(found_ecf) {
				ecf_user_count += 1;
			}
		});

		// Create the excel file itself.
		var pages = [];
		if(export_user_list) {
			pages.push({
				name : 'Users',
				info : [
					['Users Invited', user_count],
					['Users Accepted', user_accepted_count],
				],
				listing_cols : ['Name', 'Email', 'Tags', 'Status', "Joined On"],
				listing : user_rows,
			})
		}
		if(export_ecf_list) {
			pages.push({
				name : 'ECF Integration',
				info : [
					['ECF Integrations', ecf_count],
					['Users with ECF Integrations', ecf_user_count],
				],
				listing_cols : ['Name', 'Email', 'ECF Court', 'ECF Username', 'Date Added'],
				listing : ecf_rows,
			})
		}
		// Generate hte excel.
		export_data_to_excel("Docket Alarm User Export", pages);

		// Close the window.
		setTimeout(function () {
			modal_progress_close("export_users");
			done();
		}, 1000);
	});
}

var pulling_groupbilling_info = 0;
/**
 * Display the group billing information.
 */
function update_groupbilling_info($base, groupbilling_info) {
    if(groupbilling_info && groupbilling_info.user_email && user_logged_in &&
        typeof user_logged_in == "string" &&
        groupbilling_info.user_email != user_logged_in) {
        // Have billing information for a different user, null it out.
        groupbilling_info = null;
    }
	if(!groupbilling_info) {
		if(pulling_groupbilling_info && !$base) {
			console.log("Group billing pull in progress: " +
				pulling_groupbilling_info);
			return;
		}
		pulling_groupbilling_info++;
		$.getJSON("/get_groupbilling_info.ajax", {}, function(resp) {
			pulling_groupbilling_info--;
			if(resp.success) {
				// Save credit card info in a cookie so we can reuse.
				sessionSet("groupbilling_info", resp, 10 * 60);
				update_groupbilling_info($base, resp);
			} else {
				// Just log to the console here, no message, this is a backend
                // call and fails on embedded pages.
                console.error("No group billing: " + resp.error);
			}
		}).error(function (){
			pulling_groupbilling_info--;
		});
		return;
	}
	if(!$base)
		$base = $(".billing_pane .group_billing");
    if(!$base.length) {
    	// If the billing pane is not on the page, ignore.
    	return;
	}
	// List users that this user is currently offering group billing.
	if(groupbilling_info.offered_to_others && 
			groupbilling_info.offered_to_others.length) {
		var headings = ["User", "Email", "Status", "Type", "Group", "Edit"];
		var pacer_choices = null;
		if(groupbilling_info.slave_groups.length) {
			headings.splice(2, 0, "PACER");
			// Define a function which returns the dropdown menu.
			pacer_choices = function(off) {
				var choices = groupbilling_info.slave_groups.map(function (v) {
					// Figure out if this user is in this group.
					var sel = off.settings.pacer_group == v ? "selected " : "";
					return '<option ' + sel + 'value="' + v + '">' + v + 
						'</option>';
				});
				return "<select class='pacer_group' data-email='" + off.email +
					"'><option val=''>Default</option>" + choices.join("") + 
					"</select>";
			}
		}
		///////////
        // Admins / Users
		function select_admin_html(em, admin) {
			return "<select data-email='" + escape_html(em) + "' class='admin_selector'>" +
			"<option value='user' " + (admin ? "":"selected") +
				">User</option>" +
			"<option value='admin'" + (admin ? "selected":"") +
				">Admin</option></select>";
		}
		///////////
        // User Tags.
		var all_tags_dict = {
		    // Some default tags for users
            "Litigator" : true,
            "Attorney" : true,
            "Paralegal" : true,
            "Library" : true,
            "Docketing" : true,
            "Patent" : true,
            "Commercial" : true,
            "Transactional" : true,
        };
		groupbilling_info.offered_to_others.forEach(function (off) {
		    var tags = (off.settings && off.settings.tags) || [];
		    for(var t_i=0; t_i< tags.length; t_i++) {
		        all_tags_dict[tags[t_i]] = true;
            }
        });
		var all_tags = [];
		for(var tag in all_tags_dict) {
		    all_tags.push(tag);
        }
        all_tags.sort();

		function select_tags_html(em, tags) {
		    // Build the list of available tags.
		    return "<select multiple class='tags' data-email='" + escape_html(em) + "' >" +
				all_tags.map(function (t) {
					return "<option value='" + escape_html(t) + "'" +
						(tags.indexOf(t) != -1 ? " selected" : "") + ">" +
						escape_html(t) + "</option>";
				}) + "</select>";
        }
		var html = create_html_table(groupbilling_info.offered_to_others, 
			headings, function (off) {
			var cells = [
				// First column is name.
				off.name || '',
				// Then email
				off.email,
				// Then accepted/Rejected
				off.accepted ? "<span class='accepted'>Accepted</span>":
				off.rejected ? "<span class='rejected'>Rejected</span>":
				"<span class='invited'>Invited</span>",
				// User rights.
				select_admin_html(off.email, (off.settings ||{}).billing_master),
                // The tag selector.
                off.accepted ?
					select_tags_html(off.email, (off.settings || {}).tags || []) : "",
			];
			if(off.email != groupbilling_info.user_email ) {
                // Editing their account.
                cells.push(
                    "<a data-powerTip='Revoke access to their account.' data-email='" +
                    off.email + "' class='revoke' href='#'>Revoke</a>" +
                    "<a data-powerTip='Remind the user of their account with a short email.' data-email='" +
                    off.email + "' class='reinvite' href='#'>" +
                    (off.accepted ? "Remind" : "Reinvite") + "</a>" +
                    (off.accepted ?
                        "<a data-powerTip='Login as the user, see what they see.'" +
                        " data-email='" + off.email +
                        "' class='loginas' href='#'>Login</a>" : '') +
					(!off.accepted ? "<a data-powerTip='Force create a user account, " +
							"and connect them to this billing group.' " +
						"data-email='" + off.email +
						"' class='force' href='#'>Create</a>" : "")
                );
            } else {
				cells.push("");
			}
			if(pacer_choices) {
				cells.splice(2, 0, pacer_choices(off));
			}
			return cells;
		});

		function on_user_setting_changed($btn, $ui) {
			if(!$ui) {
				$ui = $btn;
			}
            var data = {emails_settings_change: $btn.data('email')};
            if ($btn.hasClass("type")) {
                data.type = $btn.val();
            } else if ($btn.hasClass("tags")) {
                data.tags = $btn.val();
            } else if ($btn.hasClass("pacer_group")) {
                data.pacer_group = $btn.val();
            }
            $loader.show();
            // Make the ajax revoke call
            $.post("/set_groupbilling_offers.ajax", data, function (resp) {
                if (resp.success) {
                    // Give it some time to settle.
                    show_usermsg("Change saved.", $ui);
                    setTimeout(update_groupbilling_info, 300);
                } else {
                    show_usermsg(resp.error, $ui, "error");
                }
                $loader.hide();
            }, "JSON");
        }
		var $loader = $base.find(".offered_to_others").parent().find(".loader");

		$base.find(".offered_to_others").html(html).parent().show()

		.find("[data-powertip]").powerTip({
            popupClass : 'small_powertip',
            placement: 'nw',
        }).end()

			// Export User Information.
		.find(".export")
			.data("powertip", "<h2>Export Billing Group</h2><p>Export all users " +
				"in your billing group into Excel. Export also contains " +
				"information on ECF integration.</p>").powerTip()
			.unbind("click").click(function () {
				var $this = $(this).addClass("changing");
				export_group_billing(function _done () {
					$this.removeClass("changing");
				});
		}).end()

		// Handle switching user groups and tags
		.find(".pacer_group, .group").change(function() {
			var $btn = $(this);
			on_user_setting_changed($(this));
		}).end()

		// Build tokenize, doesn't support chaining so do it here.
		.find(".tags").each(function _setup_tokenize() {
			var $this = $(this);
			$this.tokenize({
                displayDropdownOnFocus: true,
                newElements: true,
                nbDropdownElements: 50,
                onAddToken : function (value, text, e) {
                	var $sel = $(e.select);
                    on_user_setting_changed($sel, $sel.parent().find(".Tokenize"));
                },
                onRemoveToken : function (value, e) {
                	var $sel = $(e.select);
                    on_user_setting_changed($sel, $sel.parent().find(".Tokenize"));
                }
            });
			$this.parent().find(".Tokenize").data("powertip",
				"Put this user into a group for organized invoices. " +
				"Select from predefined groups or create your own.")
				.powerTip({popupClass : 'small_powertip'})
        }).change(function () {
        	var $this = $(this);
        	on_user_setting_changed($this, $this.parent().find(".Tokenize"));
		}).end()

		// Handle revoking the offer.
		.find(".revoke").click(function() {
			var $btn = $(this).addClass("changing");
			var data = {'emails_delete': $btn.data('email')};
			$loader.show();
			// Make the ajax revoke call
			$.post("/set_groupbilling_offers.ajax", data, function(resp) {
				$btn.removeClass("changing");
				if(resp.success) {
					// Give it some time to settle.
					show_usermsg("Access Revoked. Reloading list.", $btn);
					setTimeout(update_groupbilling_info, 300);
				} else {
					show_usermsg(resp.error, $btn, "error");
				}
				$loader.hide();
			}, "JSON");
			return false;
		}).end()

		// Handle reinviting or reminding the user
		.find(".reinvite").click(function() {
			var $btn = $(this).addClass("changing");
			var data = {emails_add: $btn.data('email'), email_users:true};
			$loader.show();
			// Make the ajax revoke call
			$.post("/set_groupbilling_offers.ajax", data, function(resp) {
				$btn.removeClass("changing");
				if(resp.success) {
					// Give it some time to settle.
					show_usermsg("Invitation resent", $btn);
				} else {
					show_usermsg(resp.error, $btn, "error");
				}
				$loader.hide();
			}, "JSON");
			return false;
		}).end()

		.find(".force").click(function() {
			var $btn = $(this).addClass("changing");
			show_confirm_usermsg({
				title: "Create User Account",
				subtitle: "<p>Automatically create this user&rsquo;s account and " +
					"enroll them in your billing group.</p>" +
					"<p>Set their name and password:</p>",
				okay_msg : "Create Account",
				form : [{
					name : 'name',
					label: 'User&rsquo;s First Last Name',
				},{
					name : 'password',
					label: 'User&rsquo;s Password',
				}, {
					type : 'checkbox',
					label : 'Send welcome email with sign-in instructions.',
					name : 'send_email',
					checked : true,
				}],
				okay: function ($form) {
					// Create the form data that we'll send.
					var params = $form.serializeArray();
					// Set the dont_send_email parameter.
					var send_email = params.length > 2 && params[2].value=="on";
					if(send_email) {
						delete params[2];
					} else {
						params.push({
							name: 'dont_send_email',
							value: true,
						});
					}
					// Add the email addresses that we'll be force-creating.
					params.push({
						name : 'emails_force' ,
						value : $btn.data('email'),
					});
					// Serialize the form data.
					var data = $.param(params, true);
					$loader.show();
					// Make the ajax create user call
					$.post("/set_groupbilling_offers.ajax", data, function(resp) {
						$btn.removeClass("changing");
						if(resp.success) {
							// Give it some time to settle.
							show_usermsg("Account created and linked to billing group.", $btn);
							setTimeout(update_groupbilling_info, 300);
						} else {
							show_usermsg(resp.error, $btn, "error");
						}
						$loader.hide();
					}, "JSON");
				},
				cancel:function () {
					$btn.removeClass("changing");
				}
			});
			return false;
		}).end()

		// Handle reinviting or reminding the user
		.find(".loginas").click(function() {
			// Make the ajax revoke call
			var em = $(this).addClass("changing").data('email');
			var f = '<form action="/login_as" method="POST">' +
				'<input type="hidden" name="username" value="' + em + '">' +
				'<input type="submit" value="Go"></form>';
			$(f).appendTo("body").find("[type=submit]").click();
			return false;
		}).end()

		// The admin/user selector.
		.find(".admin_selector").change(function() {
			var $btn = $(this);
			var val = $btn.val();
			var data = {};
			if(val == 'user') {
				data.emails_admin_delete = $btn.data('email');
			} else if(val == 'admin') {
				data.emails_admin_add = $btn.data('email');
			} else {
				show_usermsg("Bad selection: " + $btn.val(), $btn, "error");
			}
			$loader.show();
			// Make the ajax revoke call
			$.post("/set_groupbilling_offers.ajax", data, function(resp) {
				if(resp.success) {
					// Give it some time to settle.
					show_usermsg("User type changed.", $btn);
				} else {
					show_usermsg(resp.error, $btn, "error");
					// Change it back.
					$btn.val(val == 'admin' ? 'user' : 'admin');
				}
				$loader.hide();
			}, "JSON");
			return false;
        }).end();
	} else {
		$base.find(".offered_to_others").parent().hide();
	}
	if(groupbilling_info.can_offer) {
		$base.find(".offer_submit .set_groupbilling_offers").show();
	} else {
		$base.find(".offer_submit .set_groupbilling_offers").hide();
	}
	
	// List users that are currently offering this user group billing.
	if(groupbilling_info.offered_to_user && 
			groupbilling_info.offered_to_user.length) {
		var loader_html = '<img class="loader" alt="throbber" ' + 
			'src="/site_media/loader.gif" style="display:none">';
		var html = create_html_table(groupbilling_info.offered_to_user, 
			["Account Administrator", "Edit Status", ""], function (off) {
			return off.accepted || (groupbilling_info.current_accepted &&
				groupbilling_info.current_accepted.master_user_id == 
				off.master_user_id) ? [
				// The currently accepted plan.
				"<span class='current'>" + (off.name ? off.name : off.email) + 
					"</span>",
				"<a data-master_user_id='" + off.master_user_id + 
					"' href='#' class='leave'>Leave Group</a> " + loader_html,
				"You are in this group."
			]: [
				// Other Plans.
				(off.name ? off.name : off.email),
				"<a data-master_user_id='" + off.master_user_id + 
					"' href='#' class='accept'>Join Group</a>"  + loader_html,
				off.remind ? 
					" <a data-master_user_id='" + off.master_user_id + 
					"' href='#' class='reject'>Stop Reminding Me</a>" : ''
			];
		});
		$base.find(".offered_to_user").html(html).parent().show()
			// Set the accept links.
			.find(".accept").click(function() {
				groupbilling_offer_respond($base, $(this).data('master_user_id'), 
					true, false, false);
				return false;
			}).end()
			// Set the reject links
			.find(".reject").click(function() {
				groupbilling_offer_respond($base, $(this).data('master_user_id'), 
					false, true, false);
				return false;
			}).end()
			.find(".leave").click(function () {
				leave_group_billing(null, $base.find(".loader"));
			});
	} else {
		$base.find(".offered_to_user").parent().hide();
	}
}

function groupbilling_ask_join(groupbilling_info, notjoined) {
	if(!groupbilling_info) {
		groupbilling_info = sessionGet("groupbilling_info")
	}
	if(!groupbilling_info || groupbilling_info.current_accepted ||
		!groupbilling_info.offered_to_user.length) {
		if(notjoined){
			notjoined();
		}
		return;
	}
	var offers = groupbilling_info.offered_to_user.filter(function (off) {
		return off.remind;
    });
	if(!offers.length) {
		if(notjoined){
			notjoined();
		}
		return;
	}
	var off = offers[0];
	var name = (off.name ? off.name : off.email);
	var $base = $(".billing_pane .group_billing");
	show_confirm_usermsg({
		title : "Join Group Plan",
		subtitle :  "The user " + name + " has invited you to join your organization's Docket Alarm group. " +
			"Join to enable your group's features.",
		okay_msg : "Join Group",
		cancel_msg : "Ignore",
		okay : function() {
			groupbilling_offer_respond($base, off.master_user_id, true, false, false);
		},
		cancel : function () {
			if(notjoined) {
				notjoined();
			}
			groupbilling_offer_respond($base, off.master_user_id, false, false, true);
		},
		dialog_options : {
			dialogClass : "JoinGroupMsg"
		}
	});
	return true;
}

// Allow a user to accept/reject/ignore an offer to add them to group billing.
function groupbilling_offer_respond($base, master_user_id, accept, reject, delay) {
	$base.find('.loader').show();
	var data = {'master_user_id' : master_user_id,
				'accept':accept, 'reject':reject, 'delay':delay};
	$.post("/set_groupbilling.ajax", data, function _group_set(result) {
		$base.find('.loader').hide();
		if(result.success) {
			update_billing_info(true);
		} else {
			show_error_usermsg(result.error);
		}
	}, "json");
}

/**
 * Form that allows users to add people to their group billing account.
 */
function setup_groupbilling_offers_form() {
	$('.set_groupbilling_offers').ajaxForm({
		beforeSubmit : function (arr, $form) {
			$form.find(".loader").show();
		},
		success: function  (resp, statusText, xhr, $form)  { // post-submit callback
			$form.find('.loader').hide();
			if(resp.success) {
				update_groupbilling_info();
			} else {
				show_error_usermsg(resp.error)
			}
		},
		error:		function (dum, the_error) {
			$(edit_docket_id + 'form#edit_dockets .loader').hide();
			show_error_usermsg(the_error) },
		dataType: 'json'        // 'xml', 'script', or 'json' (expected server response type)
	});
}

$(document).ready(function(){
	setup_groupbilling_offers_form();
});

function leave_group_billing(on_success, $loader) {
	if(!confirm("Are you sure you want to leave this group billing " + 
			"plan?\nYou will need to join another plan or enter " + 
			"billing information of your own."))
		return false;
	$loader.show();
	$.post("/unset_groupbilling.ajax", {}, function(result) {
		$loader.hide();
		if(result.success) {
			update_billing_info(true);
			if(on_success) {
				on_success(result);
			}
		} else {
			show_error_usermsg(result.error);
		}
	}, "json");
}

// Credit Cards are defined in common.js

$(document).ready(function(){
	// The pricing window
	$(".NewWindow").click(function(event) {
		var href = $(this).attr("href");
		
		var pricing = window.open(href, '_blank',
			'width=599,height=600,scrollbars=yes,resizeable=yes,menubar=no');
		event.preventDefault();
		return false;
	});
});
/**
 * Set the submenu's visiblilty based on what is selected.
 */
function set_submenu_visibility() {
	var selected_page = $(".menuitem.selected");
	if(!selected_page.length)
		selected_page = $(".menuitem.current_page");
	var $submenu = $(".submenu");
	$submenu.show().find(".submenuitem").hide();
	// There may be up to two selected pages (one is the far left title page)
	if(selected_page.length > 2) {
		// This only happens when we search all courts. We don't handle this 
		// properly and it looks bad, but it's better than showing all submenus.
		// We'll be changing the menu interface shortly so it's okay.
		return;
	}
	var num_submenus = 0; // Count the displayed submenus
	selected_page.each(function () {
		// Show all submenu items associated with the menu item
		var $submenus = $submenu.find(".submenuitem[for='" + this.id + "']");
		$submenus.show();
		num_submenus += $submenus.length;
	});
	// Don't show a border unless there are submenus to display
	if(num_submenus)
		$submenu.css({"border-bottom-color":"#EEEFF3"});
	else
		$submenu.css({"border-bottom-color":"white"});		
}

/**
 * Updates the menu from the current URL.
 */
function update_from_state() {
	// NOTE: I'm pretty sure this is dead code.
	var state = $.bbq.getState();
	var selected_win = $("#" + state.p);
	if(selected_win.length) {
		$(".overlay").hide();
		$("#dockets_bd").hide();
		selected_win.show();
	} else {
		$(".overlay").hide();
		$("#dockets_bd").show();
	}
	update_label_widths(selected_win);
	$(".current_page").removeClass("current_page");
	var selected = state.p && state.p != '' ?
		$("#menu a[href='#p=" + state.p + "']"):
		$("#menu a").filter(function () {
			// HACK ALERT FIXME: still a bit buggy, need a better way to parse URLS
			var href = $(this).attr('href');
			return href.length==0 || href == "#" ||
				location.pathname == href ||
				location.pathname + "#" == href ||
				href.indexOf(location.pathname) == 0;
		});
	
			
	selected.each(function() {
		var q = $(this).parent();
		q.addClass("current_page");
		var parent_item = $("#" + q.attr("for"));
		if(parent_item.length)
			parent_item.addClass("current_page");
	});
	set_submenu_visibility();
}
$(document).ready(function(){
	$("a[href='/logout.html']").click(function () {
		// Clear saved session data on logout
		sessionClear();
	});
});


///////////////////////////////
// Bulk updating dockets.
/**
 * Update a set of dockets.
 * @param dockets A list of docket structures, must have court, docket set.
 * @param update_days Don't update cases updated in this many days.
 * @param matter_no
 * @param on_progress Called every
 */
function update_dockets(options) {
    var dockets = options.dockets || [];
    var update_days = options.update_days || 0;
    var matter_no = options.matter_no || "";
    var max_parallel = options.max_parallel || 1;
    var on_progress = options.on_progress || function () { };
    var on_done = options.on_done || function () { };
    var is_canceled = options.is_canceled || function () { return false; };

    var update_date = new Date();
    update_date.setDate(update_date.getDate() - update_days);

    var base_url = "/api/v1/getdocket/?login_token=&client_matter=" + matter_no;
    function update_docket(docket, docket_done) {
        if(is_canceled()) {
            docket_done();
            return;
        }
        // Check if it's already up to date.
        if(docket.result_type == "document") {
            // This is a document, don't update.
            on_progress(docket, false);
            docket_done();
            return;
        }
        if (docket.date_cached && !docket.cache_unofficial) {
            var dcache = new Date(docket.date_cached);
            if(dcache >= update_date) {
                on_progress(docket, false);
                docket_done();
                return;
            }
        }

        var get_url = base_url + "&docket=" + encodeURIComponent(docket.docket)+
            "&court=" + encodeURIComponent(docket.court);
        $.getJSON(get_url, {}, function _got_docket(resp) {
            if(resp.error) {
                on_progress(docket, false, resp.error);
            } else {
                on_progress(docket, true);
            }
            docket_done();
        }).error(function() {
            on_progress(docket, false, "Unknown Error for " + docket.docket);
            docket_done();
        });
    }
    var fns = dockets.map(function (docket) {
        return function _do_one_docket(docket_done) {
            update_docket(docket, docket_done);
        }
    });
    map_parallel(fns, max_parallel, on_done);
}

/**
 * Update all dockets that match a search query.
 * @param query The search query.
 * @param num_docs An estimate of how many documents exist. Used for progress
 * 					window, but if unknown, set to null.
 * @param update_days
 * @param matter_no
 */
function update_dockets_by_search(query, num_docs, update_days, matter_no) {
	var MAX_DOCKETS_TO_UPDATE = 10000;
    if(num_docs === null || num_docs === undefined) {
        num_docs = 5000;
    } else if(num_docs > MAX_DOCKETS_TO_UPDATE) {
        show_error_usermsg("Too many dockets to update.");
        return;
    } else if (num_docs == 0) {
        show_error_usermsg("No dockets to update.");
        return;
    }
    if(!query || !query.length){
        show_error_usermsg("Specify a query to update.");
    }
    // Make sure we only search dockets.
    query = "(" + query + ") AND is:docket AND " +
		"(is:unofficial OR date_get:(to:-" + update_days + "days))";
    var max_per_thread = 5000;
    var total_max_parallel = 10;
    var search_parallel = Math.ceil(num_docs/max_per_thread);
    var search_thread_urls = [];
    var base_url = '/api/v1/search/?limit=40&q=' + encodeURIComponent(query);
    for (var p_i=0; p_i < search_parallel; p_i++) {
        search_thread_urls.push(base_url +
            '&scroll_parallel=' + search_parallel +
            '&scroll_index=' + p_i + '&client_matter=' + (matter_no || ""));
    }

    // Create a dialog.
    var is_canceled = false;
    function _press_cancel() {
        is_canceled = true;
        $dialog.find(".cancel").addClass("done").text("Download Report")
			.click(function () {
				download_report();
				$dialog.dialog("close");
				return false;
            });
        return false;
    }
    function _on_error(err) {
    	$dialog
			.find(".cancel").text("Close").end()
			.find(".error").text(err).end()
			.find(".status").text("Stopped.").end()
			.find(".substatus").text("");
		is_canceled = true;
	}
    var $dialog = modal_progress_start("update_dockets",
        "Updating Dockets", _press_cancel)
        .find(".cancel").text("Stop").end()
        .find(".error").text("").end();

    var dockets_updated = [];
    var dockets_errors = [];
    var dockets_not_updated = [];
    var dockets_reviewed = 0;
    var num_search_results = 0;
    function _on_search_done(resp, first) {
        if(resp.error) {
            _on_error(resp.error);
            return;
        }
        if(is_canceled) {
            return;
        }
        if(!resp.search_results.length) {
            _on_search_thread_done();
            return;
        }
        if(first == "first") {
        	num_search_results += resp.count;
		}
        if(num_search_results > MAX_DOCKETS_TO_UPDATE) {
        	_on_error("Updating " + number_with_commas(num_search_results) +
				" dockets, more than max allowed of " +
				number_with_commas(MAX_DOCKETS_TO_UPDATE) + ".");
            return;
		}
        // Process
        update_dockets({
            dockets : resp.search_results,
            update_days : update_days,
            matter_no : matter_no,
            max_parallel : Math.ceil(total_max_parallel / search_parallel),
            on_progress : function _on_progress (docket, added, error) {
                if(added) {
                    dockets_updated.push(docket);
                } else {
                    dockets_not_updated.push(docket);
                }
                dockets_reviewed++;
                // num_docs may not be accurate, so use search result count.
                var total_docs = num_search_results || num_docs;
                var perc = 100.0 * dockets_reviewed / total_docs;
                var stat = "Updated " + dockets_reviewed + " of " +
                    total_docs + " dockets.";
                var substatus = "Updated: " + docket.title + ", " +
                    docket.docket + " (" + docket.court + ")";
                modal_progress_set("update_dockets", perc, stat, substatus);
                if(error && error.length) {
                    docket.error = error;
                    dockets_errors.push(docket);
                    $dialog.find(".error").append("<div>" + escape_html(error) +
                        "</div>");
                }
            },
            on_done : function _on_done() {
                _search_thread_next(resp.scroll);
            },
            is_canceled : function () {
                return is_canceled;
            }
        });
    }
    function _search_thread_next(scroll) {
        if(is_canceled) {
            return;
        }
        var scroll_url = base_url + '&scroll=' + scroll;
        $.getJSON(scroll_url, {}, _on_search_done);
    }
    var num_search_threads_done = 0;
    function _on_search_thread_done() {
        num_search_threads_done += 1;
        if(num_search_threads_done >= search_parallel) {
            modal_progress_set("update_dockets", 100, "Done.",
                "Completed review of " + num_search_results + " dockets. \n" +
                "Updated " + plural_n(dockets_updated.length, "docket") +
                ", and encountered " +
                plural_n(dockets_errors.length, "error") + "."
            );
            $dialog.find(".cancel").addClass("done").text("Download Report")
                .click(function () {
					download_report();
					$dialog.dialog("close");
            });
        }
    }
    function download_report() {
    	function _to_listing(d) {
			return [d.court, d.docket, d.title, d.link];
		}
		function _to_listing_error(d) {
			return [d.court, d.docket, d.title, d.error, d.link];
		}
    	export_data_to_excel("Docket Update Report", [{
			name : 'Dockets Updated',
			listing_cols : ["Court", "Docket", "Title", "Docket Link"],
			listing : dockets_updated.map(_to_listing)
		},{
			name : 'Dockets Not Updated',
			listing_cols : ["Court", "Docket", "Title", "Docket Link"],
			listing : dockets_not_updated.map(_to_listing)
		}, {
			name: 'Errors Updating Dockets',
			listing_cols : ["Court", "Docket", "Title", "Error", "Docket Link"],
			listing : dockets_errors.map(_to_listing_error)
		}].filter(function (page) {
			// Only show excel pages with data.
			return page.listing.length > 0;
		}));
	}
    modal_progress_set("update_dockets", 0, "Searching",
        "Searching for dockets to update.");
    search_thread_urls.forEach(function (search_url, idx) {
        $.getJSON(search_url, {}, function (resp) {
        	_on_search_done(resp, "first");
        });
    });
}

/**
 * Estimate the cost of updating a set of dockets.
 * @param query
 * @param update_days
 * @param on_done
 */
function update_dockets_by_search_cost(query, update_days, on_done) {
	if(!query || !query.length) {
		on_done({
			count : 0,
			min_cost : 0,
			max_cost : 0,
		});
		return;
	}
	query = "(" + query + ") AND is:docket AND " +
		"(is:unofficial OR date_get:(to:-" + update_days + "days))";
	var query_circuit = "(" + query + ") AND is:pacer AND is:circuit";
	var query_district = "(" + query + ") AND is:pacer AND not is:circuit";
	var query_nopacer = "(" + query + ") AND not is:pacer";

	var num_done = 0;
	var circuit_results = null, district_results = null, nopacer_results=null;
	function _search_done(type, resp) {
		if(type == 'circuit') {
			circuit_results = resp;
		} else if(type == 'district') {
			district_results = resp;
		} else if(type == 'nopacer') {
			nopacer_results = resp;
		} else {
			console.log("Bad type: " + type);
			return;
		}
		if(!circuit_results || !district_results || !nopacer_results) {
			return;
		}
		var error = circuit_results.error || district_results.error ||
			nopacer_results.error;
		if(error) {
			on_done ({
				error : error
			});
			return;
		}
		var total_pacer = circuit_results.count + district_results.count;
		var total = total_pacer + nopacer_results.count;
		on_done({
			query : query,
			num_circuit : circuit_results.count,
			num_district : district_results.count,
			count : total,
			count_paid : circuit_results.count + district_results.count,
			min_cost : .1 * total_pacer,
			max_cost : 3.0 * total_pacer,
			expected_cost : Math.ceil(.5 * circuit_results.count +
				1.0 * district_results.count),
		});
	}

	var base_url = '/api/v1/search/?limit=0&q=';
	$.getJSON(base_url + encodeURIComponent(query_circuit), {}, function (resp) {
		_search_done('circuit', resp);
	});
	$.getJSON(base_url + encodeURIComponent(query_district), {}, function (resp) {
		_search_done('district', resp);
	});
	$.getJSON(base_url + encodeURIComponent(query_nopacer), {}, function (resp) {
		_search_done('nopacer', resp);
	});
}
/**
 * Scroll an area into the screen so it's nicely visible.
 */
function scroll_and_center(top_offset, height) {
    var bottom_offset = top_offset + height;
    var scroll_bottom = $(window).scrollTop() + 5 * $(window).height() / 6;
    var $action_bar = $("#actionbar");
    var $action_bar_height = $action_bar.height();
    var action_bottom = $action_bar.offset().top + $action_bar_height;
    // Ensure it's not covered by the action bar and not leaking off the page.
    if(bottom_offset > scroll_bottom || top_offset < action_bottom) {
        // Give a bit of padding
        var padd = $(window).height() / 6 + $action_bar_height;
        if(top_offset > padd) {
            top_offset -= padd;
        }
        var scroll_time = Math.min(1000, Math.max(150,
            Math.abs($(window).scrollTop() - top_offset)/5));
        $('html, body').stop().animate({scrollTop: top_offset}, scroll_time);
    }
}

/**
 * Helper function to generate key value pairs.
 * @param key
 * @param val Does not escape HTML.
 * @param colan
 * @returns {string}
 */
function field_val(key, val, colan) {
	return "<span class='field " + key.toLowerCase().replace(/ /ig, "_") +
		"'><span class='key'>" + key + (colan ? ": " : "") +
		"</span><span class='val'>" + val + "</span></span>";
}

function relative_date(milis, skip_less_than_day) {
    var hours;
    var ago = "";
    if(milis < 0) {
        milis = -milis;
        ago = " Ago";
    }
    if(skip_less_than_day) {
        hours = milis / 1000 / 3600;
        if(hours < 24) {
            return ago.length ? "Yesterday" : "Today";
        }
    } else {
        if(milis < 400) {
            return plural_n(milis, "Miliseconds") + ago;
        }
        var seconds = Math.floor(milis / 1000);
        if(seconds < 1){
            return "< 1 Second" + ago;
        } else if(seconds < 120) {
            return plural_n(seconds, "Second") + ago;
        }
        var minutes = Math.floor(seconds/60);
        if(minutes < 90) {
            return plural_n(minutes, "Minute") + ago;
        }
        hours = Math.floor(minutes / 60);
        if(hours < 24) {
            return plural_n(hours, "Hour") + ago;
        }
    }
    // Round when talking about days, seems more natural
    var days = Math.round(hours / 24);
    if(days < 14) {
        return plural_n(days , "Day") + ago;
    }
    var weeks = Math.floor(days / 7);
    if(weeks < 13) {
        return plural_n(weeks, "Week") + ago;
    }
    var months = Math.floor(weeks / 4.3);
    if(months < 24) {
        return plural_n(months, "Month") + ago;
    }
    var years = Math.floor(months / 12);
    return plural_n(years, "Year") + ago;
}

////////////////////////////////////
// Analytics Workbench / Custom Analytics

function model_error(err, $targ) {
	show_usermsg(err, $targ, "error");
}
function model_success(msg, $div) {
	console.log("model_success: " + msg);
	var $targ = get_parent_with_class($div, "admin_analyzer");
	show_usermsg(msg, $targ && $targ.length ? $targ : null, "info");
}

/**
 * Helper class that converts a complex JSON object into HTML, and vice-versa.
 **/
function AnalyticsModel(resp, query_keys) {
	if(typeof resp.model != "object" ||
		typeof resp.model.docket_analysis_key != "object") {
		console.log("Expecting an object.");
		return;
	}
	// The name and the description are not stored within the model.
	this.name = resp.name;
	this.description = resp.description;
	this.model_id = resp.id;
	// Neither are the basic info about the case and what is and isn't allowed.
	this.allowed_outcomes = resp.allowed_outcomes;
	this.allowed_is_types = resp.allowed_is_types;
	this.case_info = resp.case_info;
	// The query, and a bunch of other rules are stored in the model.
	this.model = resp.model;
	this.query = resp.query;
	this.query_keys = query_keys;
	this.changed = false;
	this.$tags = null;
	// Keep track of how many times panes are created.
	this.reviewCreated = 0;

	// Get information on the types that we are creating, and their outcomes.
	this.tag_outcome = {};
	for(var key in this.model.docket_analysis_key) {
		var outcome_rule = this.model.docket_analysis_key[key];
		for(var o_i = 0; o_i < outcome_rule.length; o_i++) {
			var orules = new OutcomeRule(key, this, outcome_rule[o_i]);
			var tag = orules.tag;
			if(!this.tag_outcome[tag]) {
				this.tag_outcome[tag] = [];
			}
			this.tag_outcome[tag].push(orules);
		}
	}
	this.regenSorted();
}
/**
 * Build a structure that represents the model as edited that can be passed
 * back up to the server.
 **/
AnalyticsModel.prototype.getModelData = function _getModelData() {
	return {
		name : this.name,
		description : this.description,
		model : JSON.stringify(this.model)
	};
};
/**
 * Change the given button div to test or save state.
 * @param $div The div to change.
 * @param test true if change to test mode, false to change to save mode.
 * @private
 */
AnalyticsModel.prototype._to_test_save = function ($div, test) {
	if(test) {
		$div.removeClass("save").addClass("test")
			.find("span").text("Test Outcome Tags").end()
			.data("powertip", "Test your outcome tags on this docket.")
			.find("i").removeClass("fa-check").addClass("fa-vial");
	} else {
		$div.removeClass("test").addClass("save")
			.find("span").text("Save Outcome Tags").end()
			.data("powertip", "Save the tags you created below.")
			.find("i").removeClass("fa-vial").addClass("fa-check");
	}
};
AnalyticsModel.prototype.onChange = function (msg, $div) {
	this.changed = true;
	this._to_test_save(this.$html.find(".save"), true);
	this.$html.find(".test").addClass("changed");
	this.regenSorted();
	if(msg && msg.length) {
		model_success(msg, $div)
	}
	if($div) {
		$div.addClass("changed");
	}
};
AnalyticsModel.prototype.regenSorted = function _regenSorted() {
	// Get a nice sorted list of the types.
	this.sorted_tags = [];
	for(var tag in this.tag_outcome) {
		this.sorted_tags.push(tag);
	}
	this.sorted_tags.sort();
};
/**
 * Generate the UI for the HTML.
 */
AnalyticsModel.prototype.toHtml = function _toHtml() {
	this.$html = $("<div class='admin_analyzer'></div>")
			.append(this.toMenuHtml())
			// Do not show the setup menu:
			// .append(this.toSetupHtml()
			// 	.wrap("<div class='choice docket_analysis_setup'></div>").parent())
			.append(this.toTagHtml()
				.wrap("<div class='choice docket_analysis_key'></div>").parent())
			.append("<div class='choice if_docket_analyze_then_remove'>" +
				val_to_html(this.model.if_docket_analyze_then_remove, 0) +
				"</div>")
			.append("<div class='choice analysis_review'><label>" +
				"First build tags. Then test your tags. Then review." +
				"</label></div>");

	this.$html
	.find("[data-powertip]").powerTip({}).end()
	.find(".grow").click(function () {
		$(this).text() == "+" ?
			$(this).text("-").parent().find(".list").show():
			$(this).text("+").parent().find(".list").hide();
		return false;
	}).end()
	// Select the first tab and show it.
	.find(".choice").first().addClass("selected").end().end()
	.find(".chooser").first().click().end().end();
	return this.$html;
};

AnalyticsModel.prototype.selectTab = function _selectTab(tab) {
	this.$html
		.find(".choice").removeClass("selected").end()
		.find(".choice." + tab).addClass("selected").end()
		.find(".chooser").removeClass("selected").end()
		.find(".chooser[for='" + tab + "']").addClass("selected").end();

	if(tab == 'docket_analysis_key'){
		// Automatically highlight the model terms.
		$.bbq.pushState({q : this.query});
	}
};

/**
 * Templating code for the menu.
 **/
AnalyticsModel.prototype.toMenuHtml = function _toMenuHtml(tag) {
	var _t = this;
	var params = $.deparam.querystring();
	var suggest_review = 5;
	var num_reviewed = parseInt(params['workbench-reviewed'] || 0);
	if(isNaN(num_reviewed) || num_reviewed < 0) {
		num_reviewed = 0;
	}
	var next_params = $.extend({}, params);
	next_params['workbench-reviewed'] = num_reviewed + 1;

	var $menu = $("<div class='menu'>" +
		// "<a class='close' href=#><img src='/site_media/img/close.png'></a>" +
		"<label>Tag Editor</label>" +
		"<div class='status'></div>" +
		"<div class='commands'>" +
			"<a href='' class='dashboard'>" +
				"<i class='fas fa-caret-square-left'></i></a>" +
			"<div class='test'><i class='fas'></i> <span></span></div>" +
			"<a class='next'><i class='fas fa-forward'></i></a>" +
			// "<div class='save'>Save</div>" +
		"</div>" +
		"<div class='tabs'>" +
		// A selector for the types of analysis.
		// Do not show the setup menu:
		// "<a class='chooser' for='docket_analysis_setup' href='#'>Setup</a>" +
		"<a class='chooser' for='docket_analysis_key' href='#'>Tags</a>" +
		"<a class='chooser' for='analysis_review' href='#'>Review</a>" +
		// "<a class='chooser' for='if_docket_analyze_then_remove'
		// href='#'>Rules</a>" +
		"</div></div>");

	$menu
	.find(".close").click(function () {
		$(".admin_analyzer").remove();
		$("#bd").removeClass("admin_analyzer_shown").end();
		return false;
	}).end()

	.find(".chooser").click(function () {
		var for_class = $(this).attr('for');
		_t.selectTab(for_class);
		return false;
	}).end()

    .find(".dashboard").powerTip({smartPlacement:true}).data("powertip",
		"Go back to the Analytics Workbench dashboard.")
		.attr('href', '/dockets/#custom_analytics=' + _t.model_id).end()

	.find(".commands .next").powerTip({smartPlacement:true}).data("powertip",
		"Review another case in this Analytics Workbench library.")
        .attr('href', "/search/random/dockets/?" + $.param(next_params, true) +
			(window.location.hash || '')).end()

	.find(".status").html("<i class='fas " + (num_reviewed < suggest_review ?
		"fa-info" : "fa-check") + "'></i> Reviewed " +
		escape_html(num_reviewed) + " of " + suggest_review + " dockets")
	.data("powertip", "<h3>How Many Dockets Should You Review?</h3><p>We " +
		"recommend you review at least " + suggest_review + " dockets. The " +
		"more you review, the more accurate your analytics will be.</p>" +
		(num_reviewed < suggest_review ?
		"<p>When you go back to the dashboard and test, you will " +
		"get a better sense of your current accuracy.</p>":
		"<p>Now that you have reviewed " + num_reviewed + " dockets, try going" +
			" back to dashboard to run a full-scale test.</p>"))
	.powerTip({smartPlacement:true})
	.end()


	.find(".save, .test")
	.powerTip({smartPlacement:true})
	.click(function () {
		var $this = $(this).addClass("disabled");
		var data = _t.getModelData();
		var do_save = $this.hasClass("save");
		if(do_save) {
		    data.save = '1';
        }
        var endpoint = "?analyzer" + (_t.model_id ? "=" + _t.model_id : "");
		$.post(endpoint, data, function() {}, "json")
		.success(function _got_analysis(resp) {
			$this.removeClass("disabled");
			if(resp.error) {
				model_error(resp.error);
				return;
			}
			if(resp.analyzed) {
				show_analysis(resp.docket_report, resp.important_docs,
					resp.case_info);
				_t.toReviewHtml(resp.important_docs, resp.docket_report);
				_t.selectTab("analysis_review");
			}
			if($this.hasClass("save")) {
				if(resp.saved) {
				    if(do_save) {
					    model_success("Saved");
					    _t._to_test_save($this.removeClass("changed"), true);
                    } else {
				        model_error("Saved, but should not have.");
                    }
				} else if(do_save) {
					model_error("Not saved");
				}
			} else {
				// Turn the button from a test button into a save button.
				_t._to_test_save($this, false);
			}
		})
	}).end();

	this._to_test_save($menu.find(".commands .test"), true);

	return $menu;
};
AnalyticsModel.prototype.toSetupHtml = function _toSetupHtml(tag) {
	// TODO: Implement these and make them part of the full analysis structure.
	var _t = this;
	var $setup = $("<div>" +
				"<label>Analysis Name</label>" +
				'<input class="analysis_name" type="text" value="' +
					escape_html(this.name) + '">' +
				"<label>Description</label>" +
				'<textarea class="analysis_desc">' +
					escape_html(this.description) + "</textarea>" +
				"<label>Library</label>" +
				'<textarea class="analysis_query">' +
					escape_html(this.query) + '</textarea>' +
				"</div>");

	$setup.find(".analysis_name").change(function () {
		_t.name = $(this).val();
		_t.onChange('Changed name', $(this));
	}).end()
	.find(".analysis_desc").change(function () {
		_t.description = $(this).val();
		_t.onChange('Changed description', $(this));
	}).end()
	.find(".analysis_query").change(function () {
		_t.query = $(this).val();
		_t.onChange('Changed search query', $(this));
	}).end();

	return $setup;
};

AnalyticsModel.prototype._get_doc_type = function __get_doc_type(filing, type) {
	if(filing.analyze && filing.analyze.doc_types) {
		for(var d_i=0; d_i < filing.analyze.doc_types.length; d_i++) {
			var dt = filing.analyze.doc_types[d_i];
			if(type == dt.type) {
				if(!dt.date) {
					dt.date = filing.date;
				}
				return dt;
			}
		}
	}
	for(var e_i = 0; e_i < (filing.exhibits || []).length; e_i++) {
		var e_a = filing.exhibits[e_i].analyze;
		if(e_a && e_a.doc_types) {
			for(var e_d_i=0; e_d_i < e_a.doc_types.length; e_d_i++) {
				var dt = e_a.doc_types[e_d_i];
				if(type == dt.type) {
					if(!dt.date) {
						dt.date = filing.exhibits[e_i].date || filing.date;
					}
					return dt;
				}
			}
		}
	}
	return null;
};

AnalyticsModel.prototype.toDocTypeHTML = function toDocTypeHTML(
	doc_type, source_i) {
	var html = field_val("Tag", doc_type.type, true) +
		field_val("Date", doc_type.date, true);
	if(doc_type.date_measure_start) {
		var rel_date = relative_date(new Date(doc_type.date) -
			new Date(doc_type.date_measure_start));
		var from_tag = this.model.docket_analysis_date_measure[doc_type.type];
		html += field_val("Time from " + from_tag, rel_date, true);
	}
	if(doc_type.snippet && doc_type.snippet.length) {
		html += field_val("Snippet", doc_type.snippet, true);
	}
	return "<div class='doc_type' data-group-i='" + source_i + "'>" +
		html + "</div>";
};

AnalyticsModel.prototype.toReviewHtml = function _toReviewHtml(
	important_docs, docket_report) {
	var _t = this;
	_t.reviewCreated += 1;
	var this_review = _t.reviewCreated;
	var doc_types = [];
	for(var k in (important_docs || {})) {
		var dt = _t._get_doc_type(docket_report[important_docs[k]], k);
		doc_types.push(dt);
	}
	// Sort reverse chronologically like the docket.
	doc_types.sort(function(a, b) {
		a = new Date(a.date);
		b = new Date(b.date);
		return a > b ? -1 : a == b ? 0 : 1;
	});
	var html = "<label>Tags Found: " + doc_types.length + "</label><ul>";
	for(var d_i=0; d_i < doc_types.length; d_i++) {
		var dt = doc_types[d_i];
		html += "<li>" + _t.toDocTypeHTML(dt, important_docs[dt.type]) + "</li>";
	}
	html += "</ul>";
	html = "<div class='analysis_review choice'>" + html + "</div>";
	_t.$html
		.find(".analysis_review.choice").remove().end()
		.append(html)
		.find(".doc_type").click(function () {
			var source_i = $(this).data("group-i");
			if($.bbq.getState().hits == source_i) {
				// Just recenter.
				var $selrow = $(".docket_row:visible.selected");
				if($selrow && $selrow.length) {
					scroll_and_center($selrow.offset().top, $selrow.height());
				}
			} else {
				$.bbq.removeState("q");
				$.bbq.pushState({hits : source_i});
			}
			return false;
		}).end();

	function _highlight_selected_tag() {
		if(this_review != _t.reviewCreated) {
			// Another handler will handle this.
			return;
		}
		var $ana = _t.$html.find(".analysis_review.choice")
			.find(".doc_type.selected").removeClass("selected").end();
		if(!showing_rows || showing_rows.length != 1) {
			return;
		}
		$ana.find(".doc_type[data-group-i='" + showing_rows[0] + "']")
			.addClass("selected");
	}
	// Highlight the tag if it's selected.
	onEvent('docket_report_render_complete', _highlight_selected_tag);
	_highlight_selected_tag();
};

AnalyticsModel.prototype.toSingleTagHtml = function _toSingleTagHtml(tag) {
	var _t = this, $tag = null;
	var $input_name = $('<input class="tag_name" type="text" value="' +
		escape_html(tag) + '">').change(function _tag_change() {
			// Try changing the tag.
			if(!_t.changeTag(tag, $(this).val(), $(this))) {
				/// Change it back.
				$(this).val(tag);
			} else {
				// Change our copy of this tag, to the new tag value, so it can
				// change it back if it changes again and we need to revert.
				tag = $(this).val();
			}
		});
	var $outcome_rules = join_jquery(_t.tag_outcome[tag].map(
		function _add_outcome(t_o) { return t_o.toHtml();
	}));
	var outcome_tip = "Create a new outcome for this tag. Each outcome is " +
		"applied independently, so you can create multiple for the same tag.";
	var $add_outcome = $("<div class='add_outcome' data-powertip=" +
			"'" + outcome_tip + "'>Add Outcome</div>")
		.click(function () {
			if(!_t.tag_outcome[tag]) {
				model_error("Cannot find tag: " + tag);
				return false;
			}
			_t.newOutcome(tag);
			return false;
		});
	var $delete_tag = $("<div class='delete tagdelete'><img " +
		"src='/site_media/img/trash_icon.png'></div>").click(function () {
		show_confirm_usermsg({
			title : "Delete Tag",
			subtitle : "Sure you want to delete " + tag + "? ",
			okay_msg : "Yes, Delete It",
			okay : function() {
				_t.deleteTag(tag, $tag);
			}
		});
		return false;
	});

	// Handle what happens when there are duplicate tags, we can either use the
	// oldest or most recent.
	var uses_old = _t.model.if_docket_analyze_then_remove &&
		_t.model.if_docket_analyze_then_remove[tag] &&
		_t.model.if_docket_analyze_then_remove[tag].indexOf(">" + tag) != -1;
	var $duplicate = $("<div class='duplicate_tag'>" +
			"<label>If More than One Tag is Applied, Use:</label><select>" +
				"<option value='new'>Newest</option>" +
				"<option value='old'>Oldest</option></select>")
		.data('powertip', 'If there are multiple "' + tag +
			'" tags, select which to use.')
		.powerTip({smartPlacement : true})
		.find("select").change(function () {
			_t.ChangeTagDuplicates(tag, $(this).val() != "old");
		}).val(uses_old ? "old" : "new").end();

	var timing_options = "<option value=''>Initial Case Filing Date</option>" +
		this.sorted_tags.map(function(o_tag) {
			return o_tag == tag ? "" :
				"<option value='" + o_tag + "'>" + o_tag + "</option>";
	}).join("");

	var old_timing_key = _t.model.docket_analysis_date_measure &&
						_t.model.docket_analysis_date_measure[tag];
	var chk_id = "chk_" + Math.round(Math.random()*100000).toString();
	var $timing = $("<div class='timing_tag'><label>Measure Date From</label>" +
			"<select>" + timing_options + "</select>" +
			"<input type='checkbox' id='" + chk_id + "'>" +
			"<label for='" + chk_id + "'>Allow negative days</label></div>")
		.data('powertip', 'Measure the length of time between ' + tag +
			' and another tag.')
		.powerTip({smartPlacement : true})
		.find("select").change(function () {
			// The user changed the measure date from value.
			var new_val = $(this).val();
			_t.ChangeTagTiming(tag, old_timing_key, new_val,
				$timing.find("input[type=checkbox]").is(":checked"));
			old_timing_key = new_val;
		}).val(old_timing_key || '').end()
		.find("input[type=checkbox]").change(function () {
			// The user changed the negative days.
			_t.ChangeTagTiming(tag, old_timing_key, old_timing_key || '',
				$timing.find("input[type=checkbox]").is(":checked"));
		}).attr('checked', !_t.model.if_docket_analyze_then_remove ||
			!_t.model.if_docket_analyze_then_remove[old_timing_key] ||
			_t.model.if_docket_analyze_then_remove[old_timing_key]
					.indexOf("<" + tag) == -1
		).end();

	var $input_rules = join_jquery([$("<label>Tag Name</label>"),
			$input_name, $delete_tag, $("<label>Tag Outcomes</label>"),
			$outcome_rules, $add_outcome, $duplicate, $timing]);
	$tag = $input_rules.wrap("<div class='tag'></div>").parent();
	return $tag;
};

/**
 * Change the way we measure timing from one tag to another.
 * @param tag		The tag we want to measure timing to.
 * @param old_key	The previous tag that we wanted to measure timing from.
 * @param new_key 	The current tag that we wanted to measure timing from.
 * @param new_check If true, allow new_key to occur after tag.
 * @constructor
 */
AnalyticsModel.prototype.ChangeTagTiming = function _ChangeTagTiming(
	tag, old_key, new_key, new_check) {
	var _t = this;
	// Basic setup
	if(!_t.model.if_docket_analyze_then_remove) {
		_t.model.if_docket_analyze_then_remove = {};
	}
	if(!_t.model.docket_analysis_date_measure) {
		_t.model.docket_analysis_date_measure = {};
	}
	// Remove the old is remove rule.
	if(_t.model.if_docket_analyze_then_remove[old_key]) {
		var idx = _t.model.if_docket_analyze_then_remove[old_key].indexOf("<" + tag);
		if(idx != -1) {
			_t.model.if_docket_analyze_then_remove[old_key].splice(idx, 1);
		}
	}
	// Add in the new key.
	if(new_key != old_key) {
		if(new_key && new_key.length) {
			_t.model.docket_analysis_date_measure[tag] = new_key;
		} else if(_t.model.docket_analysis_date_measure &&
					_t.model.docket_analysis_date_measure[tag]) {
			delete _t.model.docket_analysis_date_measure[tag];
		}
	}
	if(!new_check) {
		if(new_key || new_key == '') {
			if(!_t.model.if_docket_analyze_then_remove[new_key]) {
				_t.model.if_docket_analyze_then_remove[new_key] = [];
			}
			_t.model.if_docket_analyze_then_remove[new_key].push("<" + tag);
		} else {
			console.warn("New key not specified.");
		}
	}
	_t.onChange('Tag timing changed to: ' + escape_html(new_key));
};

/**
 * Change the way we handle tags that have been applied multiple times to a
 * docket sheet.
 * @param tag	The tag at issue.
 * @param use_newest If true, always use the newest tag, else use the oldest.
 * @constructor
 */
AnalyticsModel.prototype.ChangeTagDuplicates = function _ChangeTagDuplicates(
	tag, use_newest) {
	var _t = this;
	// Basic setup
	if(!_t.model.if_docket_analyze_then_remove) {
		_t.model.if_docket_analyze_then_remove = {};
	}
	var new_val = _t.model.if_docket_analyze_then_remove[tag] || [];
	// Remove any old tag items.
	new_val = new_val.filter(function (r) {
		return r != "<" + tag && r != ">" + tag;
	});
	if(!use_newest) {
		new_val.push(">"  + tag);
	}
	_t.model.if_docket_analyze_then_remove[tag] = new_val;
	_t.onChange('Changed duplicate handling for "' + tag + '" to always use ' +
		(use_newest ? "newest." : "oldest."));
};
/**
 * Return html relating to all of the tags.
 */
AnalyticsModel.prototype.toTagHtml = function _toTagHtml() {
	var _t = this;
	var $tagmenu = $("<div class='tagmenu'><label>Select Tag to Edit</label>" +
		"<select class='switch_tag'></select></div>");
	var $newtag = $("<div class='add_tag'>New Tag</div>").click(function () {
		_t.newTag();
		return false;
	}).data('powertip', "Create a new tag. Tags are the building blocks " +
		"of analytics. They represent what you want to measure.")
	.powerTip({
		popupClass : 'small_powertip',
		placement: 'nw',
	});
	$tagmenu.append($newtag);
	// Now go through each of them.
	var $tags = join_jquery(this.sorted_tags.map(function _add_tag(tag, t_i) {
		// Add the tags to the selector while we're at it.
		$tagmenu.find("select").append(option_html(tag, tag, t_i == 0));
		return _t.toSingleTagHtml(tag);
	}));
	// The tag selector.
	$tagmenu.find("select").change(function () {
		// Hide all tags.
		_t.$tags.find(".tag").hide().end();
		// Find the tag to show.
		var new_val = $(this).val();
		var $show = _t.$tags.find('.tag .tag_name').filter(function () {
			return new_val == $(this).val()
		});
		if(!$show || !$show.length) {
			console.log("Could not find tag with name: " + $(this).val());
		} else {
			// Get it's parent tag class and show it.
			get_parent_with_class($show, "tag").show();
		}
		return false;
	});
	// Show the first tag.
	$tags.find(".tag").hide().end().find(".tag:first").show();

	this.$tags = join_jquery([$tagmenu,
		$("<label class='edittagdetails'>Edit Tag Details</label><br>"), $tags]);
	return this.$tags;
};
AnalyticsModel.prototype.changeTag = function _changeTag(oldT, newT, $tag) {
	if(!newT.length || newT.search(/^[^A-Z]|[^a-z]$|[^A-Za-z\d \-.]/g) >= 0) {
		model_error('Tags must be capitalized and may not end in numbers or ' +
			'punctuation: ' + escape_html(newT), $tag);
		return false;
	} else if (this.tag_outcome[newT]) {
		model_error('Tag already exists: ' + escape_html(newT));
		return false;
	}
	this.tag_outcome[newT] = this.tag_outcome[oldT];
	delete this.tag_outcome[oldT];
	this.tag_outcome[newT].forEach(function (to) {
		to.changeTag(newT);
	});
	this.$tags.find(".switch_tag, .timing_tag select")
		.find('option').filter(function () {
			return $(this).val() == oldT;
    	})
		.val(newT).text(newT);

	// Change the internal state of our date_measure structure.
	var date_measure = this.model.docket_analysis_date_measure || {};
	for(var k in date_measure) {
		if(k == oldT) {
			date_measure[newT] = date_measure[oldT];
			delete date_measure[oldT];
		} else if(date_measure[k] == oldT) {
			date_measure[k] = newT;
		}
	}
	var re_oldrule = RegExp("^[<>=]+" + oldT + "$");
	for(k in (this.model.if_docket_analyze_then_remove || {})) {
		// If changing this key, use the new one, otherwise use the exisitng.
		var new_key = k == oldT ? newT : k;
		this.model.if_docket_analyze_then_remove[new_key] =
			this.model.if_docket_analyze_then_remove[k].map(function (r) {
				// We also need to change all the subkeys if they changed.
				return re_oldrule.test(r) ? r.replaceAll(oldT, newT) : r;
			});
		// If changing this key, delete the old value.
		if(k == oldT) {
			delete this.model.if_docket_analyze_then_remove[k];
        }
    }
	this.onChange('Tag changed to: ' + escape_html(newT), $tag);
	return true;
};
/**
 * Delete a tag.
 **/
AnalyticsModel.prototype.deleteTag = function _changeTag(tag_name, $tag) {
	// Delete any remaining conditions.
	this.tag_outcome[tag_name].forEach(function (to) {
		$tag.find(".condition_row .delete").click();
	});
	// There are no outcomes left, delete the entire tag.
	delete this.tag_outcome[tag_name];
	// Delete the tag from the selector.
	var $switch_tag = this.$tags.find(".switch_tag");
	$switch_tag.find("option").filter(function () {
		return $(this).val() == tag_name;
	}).remove();
	if($tag.hasClass("tag")) {
		$tag.remove();
	} else {
		get_parent_with_class($tag, "tag").remove();
	}
	// Now select the first tag so we display something.
	for(var new_tag in this.tag_outcome) {
		$switch_tag.val(new_tag).change();
		break;
	}

	// Change the internal state of our date_measure structure.
	var date_measure = this.model.docket_analysis_date_measure || {};
	for(var k in date_measure) {
		if(k == tag_name || date_measure[k] == tag_name) {
			delete date_measure[k];
		}
	}

	this.onChange('Tag removed: ' + escape_html(tag_name));
};

/**
 * Translate a tag and outcome into the dictionary key used in the model.
 * @param tag
 * @param outcome
 * @returns {string}
 */
AnalyticsModel.prototype.getKey = function _newTag(tag, outcome) {
	return JSON.stringify(outcome && outcome.length ? [tag, outcome] : tag);
};

/**
 * Create a new outcome for an existing tag.
 **/
AnalyticsModel.prototype.newOutcome = function _newTag(tag_name) {
	if (!this.tag_outcome[tag_name]) {
		model_error('Cannot find tag: ' + tag_name);
		return false;
	}
	// Add the non-outcome to our list of outcomes.
	var all_outcomes = [""].concat(this.allowed_outcomes);
	var tag_key = null, tag_outcome = null, tag_found = false;
	for(var o_i = 0; o_i < all_outcomes.length; o_i++) {
		tag_outcome = all_outcomes[o_i];
		tag_key = this.getKey(tag_name, tag_outcome);
		if(!this.model.docket_analysis_key[tag_key]) {
			tag_found = true;
			break;
		}
	}
	if(!tag_found) {
		model_error('Setup previous outcome before adding more: ' + tag_name);
		return false;
	}

	// Add the rule to our internal state.
	var rule = { 'contents' : '' };
	if(!this.model.docket_analysis_key[tag_key]) {
		this.model.docket_analysis_key[tag_key] = [];
	}
	this.model.docket_analysis_key[tag_key].push(rule);
	var orule = new OutcomeRule(tag_key, this, rule);
	// Add it to our internal state.
	this.tag_outcome[tag_name].push(orule);
	var $orule = orule.toHtml();
	// Figure out where to put this new html.
	var $name_tag = this.$tags.find(".tag_name").filter(function () {
		return tag_name == $(this).val();
	});
	var $parent_tag = get_parent_with_class($name_tag, "tag");
	if(!$parent_tag){
		model_error("Problem adding outcome.");
		this.tag_outcome[tag_name].pop();
		return false;
	}
	// Add the new tag HTML.
	$parent_tag.find(".rule_group").parent().append($orule);
	this.onChange("Added", $orule);
	return true;
};
/**
 * Create a new tag and add it to the UI.
 *	_exist_tag_name: Should not be used, other than by newOutcome.
 **/
AnalyticsModel.prototype.newTag = function _newTag() {
	var new_tag_name = "Unnamed Tag";
	if (this.tag_outcome[new_tag_name]) {
		model_error('Tag already exists: ' + new_tag_name);
		return false;
	}
	var tag_key = this.getKey(new_tag_name);
	if(this.model.docket_analysis_key[tag_key]) {
		model_error('Tag key already exists: ' + new_tag_name);
		return false;
	}
	// Add the rule to our internal state.
	var rule = { 'contents' : '' };
	this.model.docket_analysis_key[tag_key] = [rule];
	var orule = new OutcomeRule(tag_key, this, rule);
	// Add it to our internal state.
	this.tag_outcome[new_tag_name]= [orule];
	var $orule = this.toSingleTagHtml(new_tag_name);
	// Hide the other tags.
	this.$tags.find(".tag").hide();
	// Add the new one to the HTML.
	this.$tags.append($orule);
	this.onChange("Added", $orule);
	// Add it to the selector.
	this.$tags.find('.switch_tag').append(
		option_html(new_tag_name, new_tag_name, false));
	// Just show the new tag.
	var $show = this.$tags.find('.tag .tag_name').filter(function () {
		return new_tag_name == $(this).val();
    });
	// Get it's parent tag class and show it.
	var $show_parent = get_parent_with_class($show, "tag");
	if($show_parent && $show_parent.length) {
	    $show_parent.show();
    }
	this.$tags.find('.switch_tag').val(new_tag_name);
};

/**
 * A class that controls the rule (i.e., a set of conditions) for
 * individual tags/outcome pair. Note that there may be several
 * OutcomeRule for each tag/outcome pair.
 */
function OutcomeRule(key, parent, rule) {
	this.key = key;
	var tag_outcome = JSON.parse(key);
	if(Array.isArray(tag_outcome)) {
		this.tag = tag_outcome[0];
		this.outcome = tag_outcome[1];
	} else {
		this.tag = tag_outcome;
		this.outcome = "";
	}
	this.rule = rule;
	this.parent = parent;
}

function key_to_text(key) {
	if(key == '_deadline') {
		return "Extracted Event";
	}
	return capitalize((key||'')
		.replace(/\./g, ': ')
		.replace(/[\s_]+/g, ' '))
}
function option_html(val, text, selected) {
	selected = selected ? "selected " : "";
	// Make the viewable text more user friendly.
	text = key_to_text(text);
	return "<option " + selected + "value='" + val + "'>" +
		escape_html(text) + "</option>";
}

/**
 * Each tag-outcome pair gets generated from a list of conditions that must all
 * be met. Generate the HTML for a single conditional field.
 */
OutcomeRule.prototype.toConditionHtml = function _toConditionHtml(field) {
	var _t = this;
	var found_selected = false;
	var key_fields = this.parent.query_keys.map(function (key) {
		found_selected = found_selected || key == field;
		return option_html(key, key, key == field);
	});
	if(!found_selected) {
		key_fields.unshift(option_html(field, field, true));
	}
	var $row = $("<div class='condition_row'>" +
		"<select class='field'>" + key_fields.join("") + "</select>" +
		val_to_html(_t.rule[field]) +
			"<div class='edit'><i class='fas fa-edit'></i></div>" +
			"<div class='delete'><i class='fas fa-trash-alt'></i></div>" +
			"<div class='close'><i class='fas fa-times'></i></div>" +
			"<div class='done'>Done</div>" +
		"</div>");
	$row.find(".delete").click(function _key_delete() {
		// Remove the internal state.
		var rule_i = _t.parent.model.docket_analysis_key[_t.key].indexOf(_t.rule);
		if(rule_i == -1) {
			model_error("Cannot find condition to remove.");
			return false;
		}
		delete _t.rule[field];
		// Determine how many rules are left.
		var num_keys = 0;
		for(var k in _t.rule) {
			num_keys++;
		}
		if(num_keys == 0) {
			// There are no more conditions.
			// Delete the rule from the main model structure.
			_t.parent.model.docket_analysis_key[_t.key].splice(rule_i, 1);
			if(!_t.parent.model.docket_analysis_key[_t.key].length) {
				// There are no more rules for this outcome.
				// Remove the outcome from the main model structure.
				delete _t.parent.model.docket_analysis_key[_t.key];
				// Delete this outcome.
				var rule_tag_i = _t.parent.tag_outcome[_t.tag].indexOf(_t);
				_t.parent.tag_outcome[_t.tag].splice(rule_tag_i, 1);
				if(!_t.parent.tag_outcome[_t.tag].length) {
					// There are no outcomes left, delete the entire tag.
					_t.parent.deleteTag(_t.tag, $row);
				} else {
					// Just delete this outcome.
					get_parent_with_class($row, "rule_group").remove();
				}
			} else {
				// Just remove this list of conditions in the outcome.
				get_parent_with_class($row, "rule_group").remove();
			}
		} else {
			// Remove just the HTML row.
			$row.remove();
		}
		_t.parent.onChange();
	}).end()
	.find(".edit").click(function _key_delete() {
		$row.addClass("editable");
	}).data("powertip", 'Edit the tag condition. The condition determines ' +
		'whether the tag is applied to a docket entry.')
	.powerTip({
		popupClass : 'small_powertip',
		placement: 'nw',
	}).end()
	.find(".close, .done").click(function _key_delete() {
		$row.removeClass("editable");
		return false;
	}).end()
	.find(".field").change(function () {
		// Change a field key.
		var new_field = $(this).val();
		if(_t.rule[new_field]) {
			model_error('Field "' + field + '" already exists.');
			$(this).val(field);
			return false;
		}
		_t.rule[new_field] = _t.rule[field];
		delete _t.rule[field];
		// We need to change this scoped variable as well.
		field = new_field;
		_t.parent.onChange("Field changed to: " + new_field, $(this));
	}).end()
	.find(".val").change(function () {
		// Change a value
		_t.rule[field] = $(this).val();
		_t.parent.onChange("Value changed to: " + _t.rule[field], $(this));
	}).end()
	.click(function () {
		if ($row.hasClass("editable")) {
			// Pass the click up, it's editable.
			return true;
		}
		$row.addClass("editable");
		return false;
	});
	return $row;
};

/**
 * Generate HTML for a list of rules for a single outcome.
 **/
OutcomeRule.prototype.toHtml = function _toOutcomeHtml() {
	var _t = this;
	var all_outcomes = [null].concat(this.parent.allowed_outcomes);

	// Generate options for each allowed outcome
	var found_selected = false;
	var outcomes = all_outcomes.map(function _add_outcome_choice(allowed_o) {
		found_selected = found_selected || _t.outcome == allowed_o;
		return option_html(allowed_o||'', allowed_o||'-',
			_t.outcome == allowed_o);
	});
	if(!found_selected && _t.outcome) {
		if(typeof _t.outcome != "string") {
			_t.outcome = JSON.stringify(_t.outcome);
			console.warn("Cannont handle non-string outcome: " + _t.outcome);
		}
		outcomes.unshift(option_html(_t.outcome, _t.outcome, true));
	}
	var $outcome_select = $('<select class="outcome">' + outcomes.join("") +
		'</select>').change(function () {
			// The user changes the outcome selector.
			var new_outcome = $(this).val();
			// Figure out if this outcome already exists.
			var exist_outcome = _t.parent.tag_outcome[_t.tag].filter(function(or) {
				return or.outcome == new_outcome;
			});
			// Generate the new key.
			var new_key = _t.parent.getKey(_t.tag, new_outcome);
			var dkeys = _t.parent.model.docket_analysis_key;
			// Change our parent's internal state -- add new.
			if(!dkeys[new_key]) {
				dkeys[new_key] = [];
			}
			dkeys[new_key].push(_t.rule);
			// Change our parent's internal state -- delete old.
			if(dkeys[_t.key] && dkeys[_t.key].length >= 1) {
				if(dkeys[_t.key].length == 1) {
					delete dkeys[_t.key];
				} else {
					var oldindex = dkeys[_t.key].indexOf(_t.rule);
					if(oldindex != -1) {
						dkeys[_t.key].splice(oldindex, 1)
					} else {
						model_error("Could not find old outcome.", $(this));
					}
				}
            } else {
				model_error("Could not remove old outcome.", $(this));
			}
			// Change this object's internal state.
			_t.key = new_key;
			_t.outcome = new_outcome;
			// Let our parent know something changed.
			_t.parent.onChange("Condition Added", $outcome_select);
		});

	var $table = $("<div class='rule'></div>");
	for(var field in _t.rule) {
		var $row = _t.toConditionHtml(field);
		$table.append($row);
	}
	var $add_condition = $("<div class='add_condition'>Add Condition</div>")
	.data("powertip", "Add a new condition. Each condition will be run " +
		"against each docket entry. The tag will be applied to the docket " +
		"entry if every condition is met.")
	.powerTip({
		popupClass : 'small_powertip',
		placement: 'nw',
	})
	.click(function () {
		// Create a new condition to this rule.
		if(_t.rule['']) {
			model_error("Setup previous condition before adding more.");
		} else {
			_t.rule[''] = 'Enter rule.';
			var $row = _t.toConditionHtml('');
			$table.append($row);
			_t.parent.onChange("Condition Added", $row);
		}
	});
	var $rules_html = join_jquery([
		$("<div class='condition_header'>" +
			"<label>Field</label><label>Value</label></div>"),
		$table, $add_condition], 'condition_group');
	var $rules_group = join_jquery([$outcome_select, $rules_html]);
	return $rules_group.wrap("<div class='rule_group'></div>").parent();
};
OutcomeRule.prototype.changeTag = function _changeTag(newT) {
	var old_key = this.key;
	this.tag = newT;
	this.key = this.parent.getKey(this.tag, this.outcome);
	this.parent.model.docket_analysis_key[this.key] =
		this.parent.model.docket_analysis_key[old_key];
	delete this.parent.model.docket_analysis_key[old_key];
};

function val_to_html(val, level, topclass, curclass) {
	// Recursively converts a complex dictionary structure into html.
	level = level || 0;
	var html = '';
	if(typeof val == "boolean") {
		return '<select class="' + (curclass||"val") + '">' +
			option_html("true", "true", val) +
			option_html("false", "false", !val) +
			'</select>';
	} else if(typeof val == "string") {
		var placeholder = 'Enter a boolean query as a condition for the tag.';
		return '<textarea class="' + (curclass||"val") + '" type="text" ' +
			'placeholder="' + placeholder + '">' +
				escape_html(val) + '</textarea>';
	} else if (Array.isArray(val)) {
		html = '<a class="grow">+</a>' +
			'<div class="list" style="display:none">';
		for(var v_i=0; v_i < val.length; v_i++) {
			html += val_to_html(val[v_i], level) + " ";
		}
		html += "</div>";
		return html;
	} else {
		// It's a dictionary.
		html = '<div ' + (level ? "" : "class='" + topclass + "'") + '>';
		// Sort the keys of the dictionary.
		var sorted = [];
		for(var key in val) {
			sorted.push(key);
		}
		sorted.sort();
		for(var i = 0; i < sorted.length; i ++) {
			var k = escape_html(sorted[i]);
			var v = val[sorted[i]];
			html += "<div class='keyval level_" + level + "'>" +
				'<input class="key" type="text" value="' + k + '">' +
				val_to_html(v, level + 1) + "</div>";
		}
		// Add link so they can add more entries.
		html += "<a class='addkey' href='#'>New key</a></div>";
		return html;
	}
}
function html_to_val($start) {
	// Reverse the HTML create in the above function to create a dictionary.
	var dict = {};
	$start.find("> .keyval").each(function (kv) {
		var k = $(this).find(".key").val();
		var $list = $(this).find("> .list");
		if ($list.length) {
			dict[k] = $.makeArray($list.find("> div").map(function () {
				return html_to_val($(this));
			}));
		} else {
			var v = $(this).find("> .val").val();
			if(v === 'true') {
				v = true;
			} else if (v == 'false') {
				v = false;
			}
			dict[k] = v;
		}
	});
	return dict;
}

/**
 * Create a popup that allows a user to embed a page into an iFrame.
 */
function show_embed_page_popup($embedded_obj) {
    var $dialog = show_confirm_usermsg({
        title: "Embed this Page",
        subtitle: "<p>Copy the embeddable iFrame code into your website.</p>" +
		"<p>You do not need a Docket Alarm account to view an embedded page.</p>",
        hide_okay: true,
        cancel_msg: "Close",
        form: [{
            type: 'textarea',
            name: 'embed',
        },{
        	type: 'link',
			a : '<i class="fas fa-link"></i>&nbsp;&nbsp;Copy just the link',
			href : "#",
			klass : 'copy_link'
		}],
    }).find("form textarea").addClass('embed_link').end();

    // Get newly created text area to the function
    var $textarea = $dialog.find("form textarea.embed_link");
    var $copy_link = $dialog.find("form a.copy_link").hide();
	$textarea.val("Loading link, please wait...");
	var resp_url = null;
    get_embed_link(function _success(url) {
    	// Get the proper height, with a selector hack to work on analytics pages.
        var iframe_height = $embedded_obj ? $embedded_obj.height() : 320;
        iframe_height = Math.max(Math.min(iframe_height, 800), 320)
        // Construct iframe with embed code
		var frame_html = wrap_html("", "iframe", null, null, {
        	width: "100%",
			height: iframe_height,
			style: "border:0px; max-width: 800px;",
			src: url,
			frameborder: 0,
			allowfullscreen: "true"
		}) ;
        // Set iframe code to dialog
        $textarea.val(frame_html);
        // Setup the copy button.
    	resp_url = url;
        $copy_link.show();
    }, function _error(error) {
        $textarea.val(error);
    });

    // Add a copy/paste handler.
    copy_to_clipboard($copy_link[0], function () {
		return resp_url ? resp_url : "Did not generate link.";
	}, "Embeddable link copied!");
}

/**
 * Get an embedded link to the current page.
 * @param on_success A function that takes a URL as input.
 * @param on_fail A function that takes an error message as input.
 */
function get_embed_link(on_success, on_fail) {
    var params = url_params_to_object(get_url_hash());
    // Turn on embed mode.
    params.embed = params.embed || '';
    var hash = $.param(params, true);
    // Construt the URL
    var link = window.location.origin + window.location.pathname + window.location.search;
    if(hash && hash.length){
    	link += "#" + hash;
	}
    var title = $("title").text();
    $.post("/api/v1/embed/create", {url: link, title: title}, function (resp) {
        if (resp.success && resp.link) {
        	on_success(resp.link);
		} else {
            // Report error message
            on_fail(resp.error);
        }
    }, "json");
}

// Cache the user's product information, we get all of it in a single API call.
var _product_cache = null;
/**
 * Get the user's product information.
 * @param product_key
 * @param callback
 */
function get_users_product(product_key, callback, force) {
	if(!force && _product_cache) {
		callback(_product_cache[product_key]);
		return;
	}
	// Get all the user's products.
	$.getJSON("/product/recurring/list/all.ajax", {}, function(resp) {
		if (!resp.success) {
			console.error("Product Key Error: " + resp.error);
		} else {
			 // Save in a cache
			_product_cache = resp.products;
			callback(_product_cache[product_key]);
		}
	});
}

function product_handle_billing_operation(product_key, operation, $clicked_element) {
	if (['add', 'remove'].indexOf(operation) === -1) {
		show_error_usermsg('The operation can only be add or remove.', null, $clicked_element);
		return;
	}

	function create_change_form(product_key, operation, main_change, other_changes_list, total_after_change) {
		var list_of_changes = '';
		other_changes_list.forEach(function(e) {
			list_of_changes += format_change(e);
		});
		var $change_form = "<div class='change_form'><div class='header'>" + format_change(main_change.change_description) +
			"<i class='cancel fas fa-times'></i>" +
			"<div class='additional_product_info'><div class='product_description'>" + main_change.product_description + "</div>" +
			"</div></div>" + //end header
			"<div class='price_info'>" +
			"<div class='standard_price_info'><div class='standard_price_static_text'>" +
			"<p class='price_top_text'>Monthly Charge</p>" +
			"<p class='price_bottom_text'>in addition to Docket Alarm base charge</p></div>" +
			"<div class='standard_price'>" + main_change.base_price_string + "</div></div>" +
			"</div>" + //end price_info
			((list_of_changes.length > 0) ? "<p class='list_top_text'>Other products that will change:</p>" : '') +
			"<div class='changes'>" + list_of_changes + "</div>" +
			"<div class='total_after_change'>Your new rate will be: " +
			"<div class='total_price_after_change'>" + total_after_change + "</div></div>" +
			"<div class='footer'>" +
			"<input class='" + operation + "_product do_action' data-product_key=" + product_key +
			" data-operation=" + operation + " type='button' value='Confirm Removal'/></div>";
		$change_form = $($change_form);
		if (operation === 'add') {
			$change_form.find('.changes').after("<div class='total'>Total to be charged today:" +
			"<div class='total_price'>$" + main_change.total_prorated_price.toFixed(2) + "</div>" +
			"</div>");
			if (main_change.total_prorated_price > 0.0){
				$change_form.find('.do_action').val('Confirm Purchase');
				if (main_change.proration[0] < main_change.proration[1]) {
					var proration_main_product_div = "<div class='proration_info'>" +
						"<div class='description'>First month pro rata for " + main_change.proration[0] +
						" of " + main_change.proration[1] + " days.</div>" +
						"<div class='prorated_pricing_description'>" +
						"<div class='first_month'>$" + main_change.total_prorated_price.toFixed(2) +
							"</br>first month</div>" +
						"</div>" +
						"</div>";
					// add the prorated description
					$change_form.find('.price_info').prepend(proration_main_product_div);
					$change_form.find('.price_top_text').append(" thereafter");
					$change_form.find('.standard_price_info').addClass('greyed_out');
				}
			}
			else {
				$change_form.find('.do_action').val('Confirm Re-subscription');
			}
		}
		// if the product is prorated, we add more context to the operation
		return $change_form;
	}

	function format_change(change_tuple) {
		// format the change based on the element being changed
		// possible changes are: 'activated', 'deactivated', 'price_up', 'price_down'
		function get_icon(change_string) {
			var icon_lookup = {'activated': '<i class="fad fa-plus-circle activated"></i>',
								'deactivated': '<i class="fad fa-times-circle deactivated"></i>',
								'price_up': '<i class="fad fa-arrow-circle-up price_up"></i>',
								'price_down': '<i class="fad fa-arrow-circle-up price_down"></i>',
								'unknown': '<i class="fad fa-question-circle unknown"></i>'}
			return (icon_lookup.hasOwnProperty(change_string)) ? icon_lookup[change_string] : icon_lookup['unknown']
		}
		var change_text = change_tuple[1];
		if (change_tuple.length > 2) {
			// has additional parameters, that means the pricing needs to be explained
			change_text += "; save " + change_tuple[2] + "<div class='get_credit_info'>Pro-rated credit to be applied today:" +
				"<div class='prorated_credit'>-$" + change_tuple[3].toFixed(2) + "</div></div>";
		}
		var formatted_change = wrap_html(get_icon(change_tuple[0]) +
			" <div class='change_text'>" + change_text + '</div>','div', 'change', {}, {})
		return wrap_html(formatted_change, 'div', 'change_div', {}, {})
	}

	var api_endpoint_base = '/product/recurring';
	$clicked_element && $clicked_element.addClass("changing");
	$.post(api_endpoint_base + '/check_for_changes.ajax', {'product_key': product_key, 'operation': operation},
		function _on_check_for_changes(resp) {
			$clicked_element && $clicked_element.removeClass("changing");
			if (!resp.success) {
				show_error_usermsg(resp.error, null, $clicked_element);
				return;
			}
			borderless_dialog(create_change_form(product_key, operation, resp.main_change,
				resp.other_changes_list, resp.total_after_change), {
				title: 'Product Change',
				//width: 550,
				autoOpen: true,
				classes: {
				},
				position: {my: "center", at: "center", of: window},
			}).find('.do_action').click(function () {
				$(this).addClass('changing');
				var post_data = {'product_key': product_key}
				var api_endpoint = api_endpoint_base +
					((operation === 'add') ? '/add.ajax' : '/unsubscribe.ajax');
				analytics_track("Purchase", operation, product_key);
				$.post(api_endpoint, post_data, function (resp) {
					if (!resp.success) {
						show_error_usermsg(resp.error, null, $clicked_element);
						return;
					}
					location.reload();
				}, 'json');
			}).end()
			.find('.cancel').click(function (){
				$('.change_form').dialog('close');
			});
		}, 'json');
}

function product_create_widget(product_key, product_dict, track_later) {
	// product_dict should contain the following parameters:
	// {'identifier', 'name', 'price_string', 'description', 'icon_definition', 'enabled', 'subscribed'}
	var disabled_status_text = product_dict.subscribed ? ' disabled' : ''; // this says disabled if the button should be disabled
	var icon_def_location = product_dict.icon_definition[0];
	var icon_source = product_dict.icon_definition[1];
	var is_corporate = last_billing_info && last_billing_info.billing_type == 'corporate';
	var cta_text = product_dict.subscribed ? "Subscribed": is_corporate ? "Learn More" : "More";

	function create_pricing_html() {
		var total_amount = product_dict.total_amount;
		var pricing = '<div class="pricing"><div class="price">$' + product_dict.amount +
			'/<p class="period">' + product_dict.period + '</p></div></div>';
		if (total_amount !== undefined) {
			total_amount = total_amount.toFixed(2);
			var total_price_div = '<div class="price top_price">$' + total_amount +
				' <p class="period">to start</p></div>';
			pricing = $(pricing).find(".price").addClass('bottom_price').end()
				.append(total_price_div).prop('outerHTML');
		}
		return pricing;
	}
	var icon_html = '';
	if (icon_def_location==='src')
		icon_html += '<img src="' + icon_source + '"/>';
	else
		icon_html += '<i class="' + icon_source + '"></i>';
	var product_html = '<div class="recurring_product" data-product_key="' + product_key + '">' +
		'<div class="main_info">' +
		'<div class="icon">' + icon_html + '</div>' +
		'<div class="name">' + product_dict.name + '</div>' +
		'<div class="description">' + product_dict.description + '</div>' +
		'</div>' +
		'<div class="footer">' +
		// Don't show pricing information to corporate users.
		(is_corporate || product_dict.subscribed ? "" : create_pricing_html()) +
		'<input type="button" class="add_subscription' + disabled_status_text + '" data-product_key="' + product_key
		+ '" value="' + cta_text + '"' + disabled_status_text + '>' +
		'</div></div>';

	var $product_html = $(product_html)
		.on("hover", function _hover_product() {
			// Track when users hover over purchase pane.
			var product_key = $(this).data('product_key');
			analytics_track("Purchase", "hover", product_key);
		});
	if(track_later) {
		track_later = 10;
	}
	// Check for visibility so we can makr it with an analytics track.
	setTimeout(function _wait_product_visible() {
		if($product_html.is(":visible")) {
			analytics_track("Purchase", "view", product_key);
			return;
		}
		if(track_later > 0) {
			track_later -= 1;
			setTimeout(_wait_product_visible, 2000);
		}
	}, 500);
	return $product_html;
}

/**
 * lists all products of a user.
 * @param products
 * @returns {jQuery|HTMLElement}
 */
function product_list_all_widgets(products) {
	// Convert dict to list.
	var prod_key_list = [];
	for (var product_key in products) {
		prod_key_list.push(product_key)
	}
	// If the product is invisible, do not show it to the user.
	prod_key_list = prod_key_list.filter(function (product_key) {
		// Default true, set to false if invisible.
		return products[product_key].visible !== false;
	});

	// Sort products according to price (may want to change this later).
	prod_key_list.sort(function (a, b) {
		return products[a].amount - products[b].amount;
	});

	// Create the widgets.
	var $widgets = prod_key_list.map(function (product_key) {
		return product_create_widget(product_key, products[product_key]);
	});
	return join_jquery($widgets, "recurring_product_row");
}

function enable_product_powertips() {
	$(".recurring_product [data-powertip]").powerTip({
		popupClass : 'small_powertip',
		placement: 'nw',
	});
}

function product_initialize_page() {
	var $doc = $(document);
	/* Handle recurring products */
	$doc.on('click', '.add_subscription', function() {
		const clicked_element = $(this);
		var product_key = $(this).data('product_key');
		analytics_track("Purchase", "See More", product_key);
		product_handle_billing_operation(product_key, 'add', $(this));
	});

	$doc.on('click', '.remove_subscription', function() {
		var product_key = $(this).data('product_key');
		analytics_track("Purchase", "Cancel", product_key);
		product_handle_billing_operation(product_key, 'remove', $(this));
	});
	// enable powertips
	enable_product_powertips();
}


$(document).ready(function() {
	product_initialize_page();
});

// These functions will be globally defined.
export {
	////////////////
	// Outcome granted / denied
	granted_keys, granted_test, denied_keys, denied_test,
	outcome_color_key,

	////////////////
	// Analytics Workbench Related
	// AnalyticsModel,
	// OutcomeRule,
	// model_error,
	// model_success,
	field_val,
	html_to_val,
	key_to_text,
	// val_to_html
	// option_html,

	// Bulk Updating
	update_dockets_by_search,
	// update_dockets,
	// update_dockets_by_search_cost,
	// update_freq_selectors,

	update_from_state,
	// set_submenu_visibility,

	////////////////
	// Docket Information
	get_docket_info_from_object,
	set_object_to_docket_info,

	////////////////
	// Product
	get_users_product,
	product_create_widget,
	product_handle_billing_operation,
	product_list_all_widgets,
	// product_initialize_page,
	// enable_product_powertips,

	////////////////
	// Group Billing
	export_group_billing,
	groupbilling_ask_join,
	get_group_billing_info,
	update_groupbilling_info,
	// groupbilling_offer_respond,
	// leave_group_billing,
	// setup_groupbilling_offers_form,

	highlight,
	remove_highlights,
	// highlight_inner,

	relative_date,
	scroll_and_center,

	////////////////
	// Embedding
	show_embed_page_popup,
	// get_embed_link,

	////////////////
	// Progress
	start_progress_bar,
	stop_progress_bar,
	set_progress_bar_text,
	add_to_progress,
}