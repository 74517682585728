// Javascript file for tracking dockets.
// dockets_track.js - Javascript for the docket tracking.
// In here:
// 		- Creating Docket Tracks (+editing, +deleting)
// 		- Setting Matter Numbers on Docket Tracks (+getting matters, +checking matters)
import {get_docket_info_from_object, get_group_billing_info} from "../site_media/dockets";
import {create_matter_input_widget} from "./matter_widget";

/*********************************************************************
 *
 *  Add New Docket Updaters
 *
 *********************************************************************/
function add_docket(data, on_complete, on_success, on_error, force_save) {
	// Make an ajax call to simply create the docket. Most callers
	// use the on_success parameter to call edit_docket immediately after.
	$(".search_result_loader").show();
	var func = force_save ? $.post : $.get;
	func("/add_dockets.ajax", data, function(result) {
		if(on_complete) {
			on_complete();
		}
		if(result.success) {
			on_success(result);
		} else {
			if(on_error) {
				on_error(result.error)
			} else {
				show_error_usermsg(result.error);
			}
		}
	}, "json");
}

/*********************************************************************
 *
 *  Edit The Docket Updaters
 *
 *********************************************************************/
var edit_docket_id = "#DocketEditOverlay ";
var on_docket_changed_calback = null;
$(document).ready(function _setup_edit_docket_dialog(){
	var DOCKETS_EDIT_HEIGHT = 600;
	if(!$(edit_docket_id))
		return;
	// Create the edit docket window
	borderless_dialog(edit_docket_id)
	.dialog()
	// Give it a min-height, b/c IE8 likes to add scrollers
	.parent()
		.css("background", "none").css("border", "none")
		.css("min-height", DOCKETS_EDIT_HEIGHT).end()

	.find("select").change(function() {
		update_freq_selectors(
			$(this).parent().find("select[name=update_frequency]").val(),
			$(this).parent().find("select[name=update_days]").val());
	}).end()

	.find('form').ajaxForm({
		beforeSubmit : function (arr, $form, options) {
			var found_matter = false;
			arr.forEach(function(a) {
				if(a.name == 'matter_no') {
					a.value = matter_unescape(a.value);
					found_matter = true;
				}
			});
			// It's possible the user entered the matter number into the text
			// box but didn't press enter. Do that for them.
			if(!found_matter && $form.find("[name=matter_no]").length) {
				var matter = $form.find("[name=matter_no]").val();
				arr.push({
					name : 'matter_no',
					value : matter,
				});
			}
			$(edit_docket_id + 'form#edit_dockets .loader').show();
		},
		success: function  (resp, statusText, xhr, $form)  { // post-submit callback
			var $dialog = $(edit_docket_id);
			$dialog.find('form#edit_dockets .loader').hide();
			if(resp.success) {
				$dialog.dialog("close");
				if (on_docket_changed_calback &&
					typeof on_docket_changed_calback == 'function') {
					on_docket_changed_calback.call(this, resp.alert_id, resp.docket);
				}
			} else if(set_matter_number_error(resp, $dialog.find(".matter_no"))) {
				// Do nothing further, the error was already shown.
			} else {
				var $btn = $form.find("[type=submit]");
				if(!$btn || !$btn.length) {
					$btn = null;
				}
				show_error_usermsg(resp.error, undefined, $btn);
			}
		},
		error:		function (dum, the_error) {
			$(edit_docket_id + 'form#edit_dockets .loader').hide();
			show_error_usermsg(the_error) },
		dataType: 'json'        // 'xml', 'script', or 'json' (expected server response type)
	}).end()
	.find(".close").click(function () {
		$(edit_docket_id).dialog('close');
		return false;
	})
	.find(".delete_btn").click(function () {
		var id = $(edit_docket_id + " .alert_id").text();
		delete_docket(id, function (resp) {
			$(edit_docket_id).dialog("close");
			if(on_docket_changed_calback &&
					typeof on_docket_changed_calback == 'function'){
				on_docket_changed_calback.call(this, resp.alert_id);
			}
		});
		return false;
	}).button({
		icons: { primary: "ui-icon-trash" },
		text: true,
		disabled: false
	}).end();
});

/**
 * Update the drop-down menus for editing the docket alert frequency.
 */
function update_freq_selectors(update_frequency, update_days) {
	if(!allowed_freq) {
		// This is an error.
		console.log("Updating frequency without allowed_freq.");
		return;
	}


	var $ed = $(edit_docket_id)
		.find(".weekdays_weekends, .first_day_month, .days")
			.hide().end();

	// If not defined, default to what we currently have
	if(!update_frequency)
		update_frequency = $ed.find("[name=update_frequency]").val();
	if(!update_days)
		update_days = $ed.find("[name=update_days]").val();

	var finfo = allowed_freq[update_frequency];
	if(!finfo) {
		console.log("Bad frequency selected: " + update_frequency);
		return;
	}
	$ed
		.find("[name=update_frequency]").val(update_frequency).end()
		.find("[name=update_days]").val(update_days).end();

	$ed
		.find(".description").text(finfo.Description).end()
		.find(".subdescription").text("").end()
		.find(".pricing").html(finfo.Message).end()
		.find(".subpricing").text("").end()
		.find(".weekdays_weekends input[type=checkbox]").prop('checked',
											update_days=="weekdays");

	var $info = $ed.find(".attach_filings .info").data("powertip",
		"<h3>Document Fees</h3><p>" + escape_html(finfo.Filing) + "</p>");
	if(finfo.docs_can_cost) {
		$info.removeClass("fa-info-circle").addClass("fa-dollar-sign");
	} else {
		$info.addClass("fa-info-circle").removeClass("fa-dollar-sign");
	}

	$ed.find(".set_include_older .info").data("powertip",
		"<h3>Include Older Results</h3><p>Uncheck to limit alert to results " +
		"filed within the last 3 weeks. If checked, you will be alerted " +
		"when older dockets or documents get added to Docket Alarm.</p>");

	if(update_frequency == "weekly") {
		var day="";
		switch(update_days) {
			case "0": day='Monday'; break;
			case "1": day='Tuesday'; break;
			case "2": day='Wednesday'; break;
			case "3": day='Thursday'; break;
			case "4": day='Friday'; break;
			case "5": day='Saturday'; break;
			case "6": day='Sunday'; break;
		}
		$ed.find(".subdescription")
			.text(day).end()
	} else if(update_frequency == "monthly") {
		// Monthly has its own set of options for which day in the month.
		$(edit_docket_id).find(".first_day_month")
			.show().val(update_days).attr('name', 'update_days');
	} else {
		// If docket is set to daily, show options to update on weekends.
		$(edit_docket_id).find(".weekdays_weekends")
			.show().val(update_days).attr('name', 'update_days');
	}
}

var allowed_freq = null;
function toggle_freq_chooser(force_hide, parent) {
	// Shows and hides the search bar dropdown
    var $dropdown = $(parent).find(".freq_chooser");
	if(!$dropdown.length)
		$dropdown = $("ul.freq_chooser");
    if(force_hide || $dropdown.is(":visible")) {
        // Hide it
       $dropdown.hide();
    } else {
        // Show it
        $dropdown.show()
    }

}
$(document).ready(function() {
	add_global_dropdown(
		edit_docket_id + " .update_freq ul.freq_chooser",
		edit_docket_id + " .update_freq",
		toggle_freq_chooser
	);
	$(edit_docket_id).find(".info").powerTip({
		smartPlacement:true,
		followMouse: false,
		mouseOnToPopup: false,
		closeDelay: 20,
		popupClass : 'standard_tip',
	}).data("powertip", "You may incur a fee for each attached filing.");
});

function matter_escape(m) {
	return m.replace(/&/gm, "&amp;"
				).replace(/"/gm,"&quot;").replace(/'/gm, "&#39;"
				).replace(/</gm, "&lt;").replace(/\\/gm, "&#92;");
}
function matter_unescape(m) {
	return m.replace(/&#92;/gm, "\\"
				).replace(/&lt;/gm,"<").replace(/&#39;/gm, "'"
				).replace(/&quot;/gm, "\"").replace(/&amp;/gm, "&");
}

var _num_matter_pulling = 0;
function get_all_matter_nos(updated, error) {
	// Don't go too crazy with the number of ajax calls.
	if(_num_matter_pulling > 2) {
		// Use a timeout to try again in a bit.
		console.log("Too many matter pulls, waiting.");
		setTimeout(function() {
			get_all_matter_nos(updated, error);
		}, 100 + _num_matter_pulling * 100);
		return;
	}
	// Now trying cached data, before going to the server.
	var cached_data = sessionGet("da-matter_nos", true);
	if(cached_data) {
		// We have cached data, so use it.
		updated(cached_data, true);
		return;
	}

	_num_matter_pulling = _num_matter_pulling + 1;
	var all_data = [];
	function get_recursive(offset, offset_type) {
		// Make a single ajax call to get some data.
		var data = {offset:offset, offset_type:offset_type || null};
		$.getJSON("/get_matters.ajax", data, function(data) {
			// Handle errors.
			if(!data.success) {
				_num_matter_pulling = _num_matter_pulling - 1;
				if(error){
					error(data);
				} else {
					show_error_usermsg(data.error);
				}
				return;
			}
			// Add the data to our list.
			all_data = all_data.concat(data.matter_nos);
			// There may be dups in the matter list, which we need to remove.
			var deduper = {};
			var deduped = [];
			for (var i = 0; i < all_data.length; i++) {
				var mid = all_data[i].id;
				var mtype = all_data[i].type || null;
				if(deduper[mid] === undefined) {
					// Have not seen this ID before.
					deduper[mid] = {mtype : true};
				} else if (deduper[mid][mtype]  === undefined) {
					// Have not seen this ID/type combo before.
					deduper[mid][mtype] = true;
				} else {
					// Have seen this id type.
					continue;
				}
				deduped.push(all_data[i]);
			}
			// Sort the matter numbers.
			deduped.sort(function (a, b) {
				var compa = (a.description || a.id).toString();
				var compb = (b.description || b.id).toString();
				return compa.localeCompare(compb);
			});
			// If there is more
			var complete = !data.next_offset;
			updated(deduped, complete);
			if(complete) {
				sessionSet("da-matter_nos", deduped, 60*5, true);
				_num_matter_pulling = _num_matter_pulling - 1;
			} else {
				get_recursive(data.next_offset, data.offset_type);
			}
		}).error(function(e) {
			_num_matter_pulling = _num_matter_pulling - 1;
			console.warn("Error getting matter numbers", e);
		});
	}
	get_recursive(0);
}

/**
 * Create a dropdown showing all available users.
 * @param group_billing
 * @param $cc_parent
 */
function create_cc_dropdown(group_billing, $cc_parent, cc_selected) {
	// Remove any earlier warnings.
	$cc_parent.find(".warn").remove();
	if (!group_billing.group || !group_billing.group.length) {
		// No group billing
		$cc_parent.hide().find("[name=cc]").html();
	} else {
		// Group billing, add CC options.
		var $cc_input = $cc_parent.show().find("[name=cc]");
		if(!$cc_input || !$cc_input.length) {
			console.error("Could not find CC dropdown.");
			return;
		}
		var cc_list = {};
		$cc_input.html(group_billing.group.map(function (em) {
			cc_list[em.email] = true;
			return "<option value='" + em.email + "'>" + em.name_email +
					"</option>";
		}).join(""));
		var cc_tokens = $cc_input.tokenize({
			nbDropdownElements: 200,
			displayDropdownOnFocus: true,
			maxElements: 200,
			newElements: false,
			onAddToken: function (value, text, e) {
				if (cc_list[value])
					return;
				// The user did not select a valid user, complain.
				e.tokenRemove(value);
				show_usermsg("Invalid user. " +
					(group_billing.current_accepted ?
						"To add users to your account, contact:<br>" +
						(group_billing.current_accepted.name ||
							group_billing.current_accepted.email) :
						"Add users to your account in My Account."),
					$edid.find(".cc .TokensContainer"), "warning");
			},
		}).clear();
		// Add the existing values.
		(cc_selected || []).forEach(function (em) {
			// Make sure they are still a user. If they aren't we'll show a
			// helpful message.
			var is_valid_user = group_billing.group.filter(function (g_em) {
				return g_em.email == em;
			});
			if(is_valid_user.length) {
				// Add them to the dropdown.
				cc_tokens.tokenAdd(em, em);
			} else {
				// This user is no longer valid.
				$cc_parent.append(
					"<p class='warn'>User " + wrap_html(em, "em", "email") +
						" is no longer in your billing group, and will be " +
					"removed when you save settings.</p>"
				)
			}

		});
	}
}
/**
 * Create a dialog allowing a user to edit a docket.
 * @param alert_id The id of the docket to edit.
 * @param add_docket_resp (optional) If you previously just called add_docket,
 * 						   and pass the response, we can skip an ajax call.
 * @param docket_data (optional) the original docket data that can be used to
 * 					  reset the title.
 */
function edit_docket(alert_id, add_docket_resp, docket_data) {
	var $edid = $(edit_docket_id);
	$edid
		.find("form#edit_dockets").hide().end()
		.find(".loader").show().end()
		.find(".alert_id").text(alert_id).end()
		.dialog("option", "dialogClass", "EditDocketDialog" )
		.dialog("option", "height", "auto" )
		.dialog("open");

    /**
     * If there is any integration with this docket tracker (i.e., Clio, then
     * adjust the dialog accordingly.
     * @param matter
     */
	function check_warn_integration(matter) {
        if(matter == null) {
            matter = $edid.find("[name=matter_no]").val();
        }
        var mtype = '';
        try {
            mtype = JSON.parse($("<div>" + matter + "</div>").text()).type;
        } catch(error) {
        	mtype = '';
		}
        if(mtype == 'clio') {
            var txt = "<p>The matter you selected is connected to Clio.</p>";
            $edid.find(".try_push").show()
                .find("label").text("Do One-Time Document Sync with Clio");
        } else {
            $edid.find(".try_push").hide().end();
        }
    }
	function reposition(n_p){
		get_parent_with_class($edid, "EditDocketDialog").position($edid.dialog("option", "position"));
	}
	function setup_dialog(resp, group_billing) {
		if (!group_billing) {
			get_group_billing_info(function (binfo) {
				setup_dialog(resp, binfo);
			});
			return
		}
		if (!resp.docket.id && (!alert_id || alert_id == 'None')) {
			console.info("Editing a new docket.")
		} else if (alert_id.toString() != resp.docket.id.toString()) {
			// Probably a bug, but I'm not confident it can never happen.
			// Maybe only check add_docket_resp with resp.docket.id.
			console.warn("Alert ID and response alert ID don't match.");
		}
		$edid
			.find(".loader").hide().end()
			.find("form#edit_dockets").show().end();
		reposition();
		if (!resp.success || !resp.docket) {
			$edid.dialog("close");
			show_error_usermsg(resp.error);
			return;
		}

		var d = resp.docket;
		// If the user changed the title, give them the option to undo it.
		var undo_title =
			d.title_original && d.title_original != d.title ? d.title_original :
				(docket_data || {}).title && docket_data.title != d.title ?
					docket_data.title : false;
		$edid
			.find(".case_alert_title")
			.val(d.title)
			.css("height", '0px')
			.css("height", $edid.find(".case_alert_title")[0].scrollHeight).end()
			.find(".undo")
			.toggle(undo_title ? true : false)
			.click(function () {
				if (undo_title) {
					// Reset the title.
					var ctitle = $edid.find(".case_alert_title")
						.val(undo_title);
					// Resize the title so it fits.
					ctitle.css("height", '0px')
						.css("height", ctitle[0].scrollHeight);
				} else {
					// Shouldn't happen.
					$(this).hide();
				}
			})
			.data("powertip", "<p>Reset back to original title.</p>")
			.powerTip({}).end()
			.find(".docket_no span").text(d.docket).end()
			.find(".court_name span").text(d.court).end()
			// Settings to locate the docket.
			.find(".id").val(d.id).end()
			// We only need these if we don't have an alert id.
			.find("[name=docket]").val(d.docket).end()
			.find("[name=court]").val(d.court).end()
			.find("[name=court_id]").val(d.court_id).end()
			.find("[name=link]").val(docket_data && docket_data.link).end()
			.find("[name=title]").val(docket_data && docket_data.title).end()
			.find("[name=sig]").val(docket_data && docket_data.sig).end()
			// Additional docket settings.
			.find("a.internal_link").attr("href", d.internal_link).end()
			.find("input[name='newest_entries_first']")
			.prop('checked', d.newest_entries_first).end()
			// Default to enabling any hidden flags.
			.find("input[type='hidden'][name='enabled']").val(true).end()
			// If there's a checkbox, then set that to our current state.
			.find("input[type='checkbox'][name='enabled']")
			.val('checked', d.enabled).end()
			.find("input[name='attach_filings']")
			.prop('checked', d.attach_filings)
			.change(function () {
				check_warn_integration();
			}).end()
			.find("input[name='attach_export']")
			.prop('checked', d.attach_export).end()
			.find(".attach_export")
			.toggle(d.allow_attach_export ? true : false).end()
			.find("input[name='set_include_older']")
			.prop('checked', d.include_older || false).end()
			.find(".set_include_older")
			.toggle(d.allow_set_include_older ? true : false).end()
			.find("select[name=update_frequency]")
			.val(d.update_frequency).end()
			.find("[name=alert_filter]").val(d.alert_filter || "").end()
			.find("#docpricelink")
			.attr('href', "/dockets/pricing?id=" + d.id).end()
			.find(".save_msg.ecf_msg").text(d.ecf_last_connected ?
			"This alert is connected via ECF and does not need to be " +
			"enabled to receive alerts." : "")
			.toggle(d.ecf_last_connected).end()
			.find("[title]").powerTip({
			popupClass: 'small_powertip'
		}).end();

		d.hide_docketno ? $edid.find(".docket_no").hide() :
			$edid.find(".docket_no").show();
		d.track_message ?
			$edid.find(".track_message").show().text(d.track_message) :
			$edid.find(".track_message").hide();
		d.hide_court ? $edid.find(".court_name").hide() :
			$edid.find(".court_name").show();
		d.has_filings ? $edid.find(".attach_filings").show() :
			$edid.find(".attach_filings").hide();

		var $freq = $edid.find(".update_freq");
		allowed_freq = resp.allowed_freq;
		var update_daily_days = d["update_days"] == "weekdays_weekends" ?
			"weekdays_weekends" : "weekdays";
		var dailys = "";
		for (k in allowed_freq) {
			if (!allowed_freq[k] || k == "weekly" || k == "monthly")
				continue;
			dailys += "<li class='show' data-freq='" + k +
				"' data-days='" + update_daily_days + "'>" +
				allowed_freq[k]['Description'] + "</li>";
		}
		if (dailys.length)
			$freq.find(".daily").html(dailys);
		else
			$freq.find(".daily");

		if (allowed_freq["weekly"]) {
			$freq.find("[data-freq=weekly]").addClass("show");
		} else {
			$freq.find("[data-freq=weekly]").removeClass("show");
		}
		if (allowed_freq["monthly"]) {
			$freq.find("[data-freq=monthly]").addClass("show");
		} else {
			$freq.find("[data-freq=monthly]").removeClass("show");
		}

		// Hide the entire category if there are no avaialble options.
		$freq.find(".freqtype").each(function (index, obj) {
			$(obj).find("ul li.show").length ? $(obj).show() : $(obj).hide();
		});

		// Setup the selector
		$freq.find(".description, .pricing, .arrow_holder").unbind("click").click(function () {
			toggle_freq_chooser(false, $freq);
		});

		// Handle the only weekday checkbox
		$edid.find(".weekdays_weekends input[type=checkbox]")
			.unbind("click").click(function () {
			var update_days = $(this).prop('checked') ?
				'weekdays' : 'weekdays_weekends';
			$freq.find(".daily li").attr('data-days', update_days);
			update_freq_selectors(null, update_days);
		});

		$freq.find("ul.freq_chooser ul li")
			.unbind("click").click(function () {
			update_freq_selectors($(this).attr('data-freq'),
				$(this).attr('data-days'));
			toggle_freq_chooser(true, $freq);
		});

		// Handle Enable/disable
		var disable_click = function () {
			$edid.find(".loader").show();
			enable_disable_docket(alert_id, false,
				function () { // oncomplete
					$edid.find(".loader").hide();
				},
				function () { // onsucces
					$edid.dialog("close");
					if (on_docket_changed_calback &&
						typeof on_docket_changed_calback == 'function') {
						on_docket_changed_calback.call(this, alert_id);
					}
				});
		};
		var save_wo_enable = function () {
			$edid.find(".loader").show().end()
				.find("input[type='hidden'][name='enabled']")
				.val(d.enabled).end()
				.find("form#edit_dockets").submit();
			return false;
		};

		if (d.enabled == false) {
			// Allow you to save changes w/o starting tracking (e.g. ECF).
			$edid.find(".disable")
				.show().text("Save changes without enabling")
				.unbind("click").click(save_wo_enable).end()
				.find("input[type=submit]").val("Start Tracking");
		} else {
			// Allow disable tracking.
			$edid.find(".disable")
				.show().text("Disable this alert")
				.unbind("click").click(disable_click).end()
				.find("input[type=submit]").val("Save Tracking Settings");
		}

		// Setup the pricing and frequency choice
		if (allowed_freq[d.update_frequency]) {
			update_freq_selectors(d.update_frequency, d.update_days);
		} else if (allowed_freq) {
			// In the odd case that their docket is set to a disallowed
			// state, then just show the first allowed one.
			console.warn("Selected frequency (" + d.update_frequency +
				") not allowed");
			for (var k in allowed_freq) {
				if (allowed_freq[k]) {
					update_freq_selectors(k, k == 'weekly' ? "0" : "weekdays");
					break;
				}
			}
		} else {
			console.log("No Allowed Freqs");
		}
		create_cc_dropdown(group_billing, $edid.find(".cc"), d.cc);

		/////
		// Populate the matter numbers dropdown.
		var $input = $edid
			// Remove any error state in the matter field.
			.find(".matter_error").removeClass("matter_error").end()
			// Return the input field.
			.find("[name=matter_no]").change(function () {
				check_warn_integration($(this).val());
			});
		if ($input.length) {
			create_matter_input_widget($input, {
				input_autogrow : false,
			}, d.matter_no);
			check_warn_integration($input.val());
		}

		// Recenter
		reposition();
    }

    if(add_docket_resp) {
		setup_dialog(add_docket_resp);
	} else {
		$.get("/edit_dockets.ajax", {id:alert_id}, function _after_edit_ajax(resp) {
			if(!resp.success) {
				$edid.dialog("close");
				show_error_usermsg(resp.error);
				return;
			}
			setup_dialog(resp);
		}, "json");
	}
}

function enable_disable_docket(alert_id, enabled, on_complete, on_success, is_pacer_dashboard) {
	console.log(is_pacer_dashboard);
	var in_data = {
		id : alert_id,
		enabled : enabled ? "True":"False"
	};
	if(is_pacer_dashboard === 'True'){
		in_data.pacer_dashboard_fields = 'True';
	}
	$.post("/enable_disable_dockets.ajax", in_data, function (resp) {
		if(on_complete) on_complete();
		if(resp.success) {
			on_success(resp);
		} else {
			show_error_usermsg(resp.error);
		}
	}, "json");
}

function delete_docket(alert_id, on_success) {
	if(confirm("Stop tracking this alert?")) {
		$("#dockets_loader .loader").show();
		$.post("/delete_dockets.ajax", {id:alert_id}, function(result) {
			$("#dockets_loader .loader").hide();
			if(result.success) {
				on_success(result);
			} else {
				show_error_usermsg(result.error);
			}
		}, "json");
	}
}

/**
 * Show a pop-up dialog of all of the users subscribed to a matter, and
 * allow a user to add / remove different users. See issue #258 for details.
 * @param matter The matter name
 * @param all_dockets A list of alerts in the matter.
 * @param group A list of user structures that are in this user's billing group.
 * @param on_success An optional callback function.
 */
function edit_docket_group(matter, all_dockets, group, on_success) {
	var $groupover = $("#EditAlertGroupOverlay");
	borderless_dialog($groupover, {
		dialogClass : 'group',
		width : window.innerWidth < 600 ?
			9 * window.innerWidth / 10 :
			Math.min(850, window.innerWidth*3/4),
	});
	$groupover
		.dialog("open")
		.dialog("option", "height", "auto" )
		.find(".matter").text(matter).end()
		.find("ul.current").html("").end()
		.find("ul.other").html("").end();
	var MAX_INITIAL_SHOW = 9;
	var POWERTIP_OPTIONS = {smartPlacement:true, popupClass : 'tagtip'};
	// Create a dictionary keyed off of all email addresses.
	var group_d = {};
	group.forEach(function (g) {
		group_d[g.email] = g;
	});
	// Add a dictionary to each docket entry of the CCed email for more
	// efficient lookups and to avoid O(N^2) issues.
	var has_bad_email = false;
	var num_is_master = 0;
	var other_masters_d = {};
	all_dockets.forEach(function (d) {
		d.cc_dict = {};
		(d.cc || []).forEach(function (email) {
			if(group_d[email] == undefined) {
				// This can occur if a user was added to a docket, but then
				// removed from the billing group.
				has_bad_email = true;
			} else {
				d.cc_dict[email]= true;
			}
		});
		// Also determine if we're the master.
		if(!d.master_user || d.master_user == user_logged_in){
			num_is_master += 1;
		} else {
			other_masters_d[d.master_user] = true;
		}
	});
	// Get a list of all of the other master users.
	var other_masters = [];
	for(var other_m in other_masters_d) {
		other_masters.push(other_m);
	}
	function basic_user_html(u) {
		return "<li class='user'><img src='" + get_gravatar(u.email) + "'>" +
				"<span class='name'>" + (u.name||u.email) + "</span>" +
				"<span class='email'>" + u.email + "</span>" +
				"<span class='status'></span>" +
				"<span class='btn'><i class='fa normal'></i>" +
				"<i class='fa hover'></i></span>" +
			"</li>";
	}
	function set_user_status($user, num_dockets) {
		var status = num_dockets == 0 ? "none":
				num_dockets < all_dockets.length ? "some" :"all";
		// Only show a message if the user is covering some of the dockets.
		var msg = status != "some" ? "":
			"This user is subscribed to " + num_dockets + " of " +
			plural_n(all_dockets.length, "alert") + " in this matter. Click" +
				" to add to all.";
		$user.removeClass("all some none").addClass(status)
			.find(".btn .normal")
				.removeClass("fa-check fa-plus-circle fa-exclamation-circle")
				.addClass(status == "all" ? "fa-check":
					status == "some" ? "fa-exclamation-circle": "fa-plus-circle")
			.end().find(".btn .hover")
				.removeClass("fa-plus-circle fa-times")
				.addClass(status == "all" ? "fa-times": "fa-plus-circle")
			.end().find(".status").text(status == "some" ?
				"(" + num_dockets + "/" + all_dockets.length + ")" : "")
			.end()
			.data("powertip", msg);
	}
	// Count up the users that are added v. not added.
	var current_i = 0, other_i = 0;
	//
	function add_user(u, u_i) {
		// Determine how many dockets this user is listed on.
		var num_dockets = 0;
		all_dockets.forEach(function (d) {
			if(d.cc_dict[u.email]) {
				num_dockets += 1;
			}
		});
		var do_hide;
		if(num_dockets == 0){
			other_i += 1;
			do_hide = other_i > MAX_INITIAL_SHOW;
		} else {
			current_i += 1;
			do_hide = current_i > MAX_INITIAL_SHOW;
		}
		var $user = $(basic_user_html(u))
			.addClass(do_hide ? "start_hidden" : "")
			.click(function () {
				if(num_dockets > 0 && num_dockets < all_dockets.length) {
                    // If not covering all, start covering all.
                    num_dockets = all_dockets.length;
				} else if (num_dockets == 0) {
					// We were covering none, now add all.
					num_dockets = all_dockets.length;
					// Move to current users.
					$user.removeClass("start_hidden")
						.prependTo($groupover.find(".current"));
					// Show one more user in the other section.
					$(".section_other .start_hidden")
						.first().removeClass("start_hidden");
				} else if (num_dockets == all_dockets.length) {
					// Remove the user.
					num_dockets = 0;
					// Move to the other users
					$user.removeClass("start_hidden")
						.prependTo($groupover.find(".other"));
					// Show one more user in the current section.
					$(".section_current .start_hidden")
						.first().removeClass("start_hidden");
				}
				set_user_status($user, num_dockets);
				update_show_more();
			})
			.appendTo($groupover.find(num_dockets > 0 ?
				".current" : ".other"))
			.data('email', u.email)
			.powerTip(POWERTIP_OPTIONS);
		set_user_status($user, num_dockets);
	}
	group.forEach(add_user);
	function update_show_more() {
		// Selected users.
		var $sel_users = $groupover.find(".section_current .user");
		var num_sel = $sel_users.length;
		var num_sel_hidden = $sel_users.filter(".start_hidden").length;
		// The other users
		var $other_users = $groupover.find(".section_other .user");
		var num_other = $other_users.length;
		var num_other_hidden = $other_users.filter(".start_hidden").length;
		// Non selected users.
		var oth_i = $groupover.find(".section_other .user").length;
		$groupover.find(".section_current .show_more")
			.toggle(num_sel_hidden > 0)
			.text(num_sel_hidden > 0 ? "Showing " + (num_sel - num_sel_hidden) +
				" of " + plural_n(num_sel, "user") + ". Click to show.": "")
			.end()
			.find(".section_other .show_more")
				.toggle(num_other_hidden > 0)
			.text(num_other_hidden > 0 ? "Showing " + (num_other - num_other_hidden) +
				" of " + plural_n(num_other, "user") + ". Click to show.": "")
			.end();
	}
	$groupover
	// Setup the filter.
	.find(".filter input").unbind("keyup").keyup(function () {
		// Lower case to be case insensitive.
		var filter = $(this).val().toLowerCase();
		$groupover.find(".section_other").toggleClass("filtered", filter.length > 0)
			.find(".user.filtered").removeClass("filtered").end()
			.find(".user").each(function (v_i, el) {
				var $v = $(el);
				if($v.text().toLowerCase().search(filter) < 0) {
					$v.addClass("filtered");
				}
        	});
	}).val("").end()
	// Remove the filtered state from the previous dialog.
	.find(".filtered").removeClass("filtered").end()
	.find(".section").removeClass("show_hidden").end()
	.find(".show_more").unbind("click").click(function _show() {
		get_parent_with_class($(this), "section").addClass("show_hidden");
		$(this).hide();
		return false;
	}).end()
	.find(".other_masters").text(other_masters.length == 0 ? "":
		plural(other_masters.length, "Alert owner") + ": " +
		other_masters.join(", ")).end()
	.find(".cancel, .close").unbind("click").click(function () {
		$groupover.dialog("close");
		// Force close popups as there may be an error popup open which is
		// causing the user to close the dialog.
		$.powerTip.hide(null, true)
		return false;
	}).end()
	.find(".okay")
		.find("span").text(num_is_master == all_dockets.length ? "Save":
				num_is_master == 0 ? "Request Changes":
				"Save and Request Changes").end()
		.powerTip(POWERTIP_OPTIONS)
		.data('powertip', num_is_master == all_dockets.length ? null:
				num_is_master == 0 ? "You aren't the administrator for these " +
					"alerts. Make the change here, and we'll email the " +
					"administrator for their approval.":
				"You aren't the administrator for all of the alerts in " +
					"this matter. Changes will be sent to " +
					"the alert administrator for approval.")
	.unbind("click").click(function () {
		// Setup the data we'll be sending.
		var data = {
			ids : all_dockets.map(function (d) { return d.id; }) || "",
			cc : $groupover.find(".current .user").toArray().map(function(el) {
				return $(el).data('email');
			}),
		};
		if(!data.cc.length){
			// Make sure we clear it out.
			data.cc = '';
		}
		// Make the change.
		var $btn = $(this).addClass("changing");
		$.post("/edit_batch_dockets.ajax", data, function() { }, "json")
		.success(function(result) {
			$btn.removeClass("changing");
			if(on_success) {
				on_success(result);
			}
			$groupover.dialog("close");
		}).error(function (result) {
			$btn.removeClass("changing");
			show_error_usermsg("Error Changing Matter");
		});
	});
	update_show_more();
}

function create_blank_docket($btn) {
	$btn.addClass("changing");
	$.post('/edit_custom_docket.ajax', {}, function() {}, "json")
	.success(function (result) {
		$btn.removeClass("changing");
		if(!result.success) {
			show_error_usermsg(result.error, "error", $btn);
			return;
		}
		window.location = result.link;
	});
}


/**
 * When a user clicks to add a docket. Used after several types of search.
 */
function on_add_docket_click() {
	if(show_signup_dialog(false, false, true)) {
		return;
	}
	var $this = $(this);
	// Find the nearest loader
	var loader = $this.addClass('clicked');
	while(loader.length && !loader.find(".loader").length)
		loader = loader.parent();
	if(loader.length) {
		loader = loader.find(".loader").show();
	}
	var item = $this;
	while(item.length && !item.find(".title, .json, .sig").length)
		item = item.parent();
	var data = get_docket_info_from_object(item);
	add_docket(data,
		function _on_complete() {
			loader.hide();
			$this.removeClass('clicked');
		},
		function _on_success(result) {
			edit_docket(result.alert_id, result, data);
		}
	);
}


export {

	////////////////
	// Matter Related
	get_all_matter_nos,
	matter_escape,
	matter_unescape,


	////////////////
	// Billing
	create_cc_dropdown,

	////////////////
	// Docket Editing
	add_docket,
	create_blank_docket,
	delete_docket,
	edit_docket,
	edit_docket_group,
	enable_disable_docket,
	// toggle_freq_chooser,
	on_docket_changed_calback,
	allowed_freq,

	on_add_docket_click,

}